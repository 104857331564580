
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import { NotificationService } from "app/talk/services/notification.service";
import { take } from "rxjs";

@Injectable()
export class ToastService {

  constructor(private snackBar: MatSnackBar,
              private notificationService: NotificationService,
              private translate: TranslateService) {
  }

  show(translationKey: string, timeout = 2000) {
    this.notificationService.openSnackBarWithTranslation(translationKey, {}, timeout);
  }

  showSnackbar(translationKey: string, timeout = 6000, handler?: any, buttonText?: string) {
    let translationTexts = [translationKey];
    if (buttonText) {
      translationTexts.push(buttonText);
    }
    if (!buttonText) {
      this.notificationService.openSnackBarWithTranslation(translationKey, {}, timeout);
    } else {
      this.translate.get(translationTexts).pipe(take(1)).subscribe(msg => {
        this.notificationService.openSnackBar(msg[translationKey],
          "action",
          msg[buttonText],
          "",
          timeout,
          "bottom",
          "left").subscribe(v => {
            if (typeof handler === "function" && v !== "dismiss") {
              handler(v);
            }
          });
      });
    }

  }

  showMatSnackBar(translationKey: string, duration = 5000) {
    this.translate.get(translationKey).pipe(take(1)).subscribe(text => {
      this.notificationService.openSnackBar(text,
        "close",
        "",
        "",
        duration,
        "bottom",
        "left").subscribe();
    });
  }

  showMatSnackBarPlainText(text: string, duration = 5000, action = "X") {
    this.snackBar.open(text, action, {
      duration: duration
    });
  }
}
