/* eslint no-console: 0 */

/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { Observable, Subject, BehaviorSubject, forkJoin, take, filter, debounceTime } from "rxjs";
import { Contact } from "../../models/contact.model";
import { Conversation, RoomMembersOwnerAvatar } from "../../models/conversation.model";
import { Message } from "../../models/message.model";
import { openDB, deleteDB } from "idb/with-async-ittr.js";
import { Broadcaster } from "../../shared/providers/broadcaster.service";
import { Store } from "@ngrx/store";
import { TalkRootState } from "../../reducers/index";
import {
  getUserProfile
} from "../../../reducers/index";
import { LoggerService } from "app/shared/services/logger.service";
import { DeleteMessages, MessageBulkAppend } from "app/talk/actions/message";

const MESSAGES_DB_STORE = "MessagesStore";
const ATTACHMENTS_DB_STORE = "AttachmentsStore";
const CONVERSATIONS_DB_STORE = "ConversationsStore";
const CONVERSATION_SETTINGS_DB_STORE = "ConversationSettingsStore";
const CONTACTS_DB_STORE = "ContactsStore";
const CURRENT_USER_DB_STORE = "CurrentUserStore";
const AVATAR_DB_STORE = "AvatarStore";
//
const OMEMO_DEVICES_DB_STORE = "OMEMODevicesStore";
const OMEMO_WHISPER_DB_STORE = "OMEMOWhisperStore";
const OMEMO_IDENTITY_KEY_PAIR_DB_STORE = "OMEMOIdentityKeyPairStore";
const OMEMO_IDENTITY_KEY_DB_STORE = "OMEMOIdentityKeyStore";
const OMEMO_PRE_KEY_DB_STORE = "OMEMOPreKeyStore";
const OMEMO_SIGNED_PRE_KEY_DB_STORE = "OMEMOSignedPreKeyStore";
const OMEMO_SESSION_DB_STORE = "OMEMOSessionStore";
const OMEMO_DECRYPTQUEUE_DB_STORE = "OMEMOMessagesToDecryptStore";
const OMEMO_ERROR_DB_STORE = "OMEMOErrorStore";
const OMEMO_LAST_ACTIVE_DB_STORE = "OMEMOLastActiveStore";

const INDEX_BY_TARGET = "by-target";
const INDEX_BY_SORT_ID = "by-sort-id";
const INDEX_BY_TIMESTAMP = "by-timestamp";
const INDEX_BY_EXPIRY = "by-expiry";
const INDEX_BY_TARGET_AND_TIMESTAMP = "by-target-timestamp";
const INDEX_BY_JID = "by-jid";
const INDEX_BY_MID = "by-mid";
// const INDEX_BY_JID_AND_ID = "by-jid-id";

const ORIGINAL_DB_NAME = "VNCtalkOfflineDatabase";


@Injectable()
export class DatabaseService {
  dbName = ORIGINAL_DB_NAME;

  dbVersion = 12;
  // A list of all versions:
  //  1 - initial
  //  2 - initial + added omemo stores
  //  3 - cleanup storage before release (release version)
  //  4 - added conversation settings store
  //  5 - added conversation index by timestamp
  //  6 - added message index by expiry
  //  7 - added avatar store
  //  8 - fix update conditions
  //  9 - fix possible errors
  // 10 - add omemo decrypt store
  // 11 - add omemo error store
  // 12 - add omemo last active store

  broadcaster: Broadcaster;
  worker: Worker;

  subjectMap = {};

  currentUserEmail: string;
  isCheckedForNewUser = false;

  db: any;

  constructor(broadcaster: Broadcaster,
    private logger: LoggerService,
  private store: Store<TalkRootState>) {
    this.logger.info("[IndexedDBService][constructor]");

    this.initIndexedDBWorker();

    this.store.select(getUserProfile).pipe(filter(profile => !!profile), take(1)).subscribe(currentProfile => {

      this.logger.info("[IndexedDBService][constructor] currentProfile.user", currentProfile.user.email, this.dbVersion);

      this.currentUserEmail = currentProfile.user.email;

      this.dbName = `VNCtalkOfflineDatabase_${currentProfile.user.id}`;

      this.worker.postMessage({type: "init", dbName: this.dbName, dbVersion: this.dbVersion});

      this.logger.info("[IndexedDBService][constructor] dbName", this.dbName, this.dbVersion);

      // open DB
      this.idbContext().then(() => {
      });

    });

    this.broadcaster = broadcaster;
  }

  private initIndexedDBWorker() {
    if (typeof Worker !== "undefined") {
      // Create a new
      this.worker = (new Worker(new URL("../../../indexeddb.worker", import.meta.url), { type: "module" }));
      this.logger.info(`[IndexedDBWorker] init done.`, this.worker);
      this.worker.addEventListener("message", (msg) => {
        if (msg && msg.data && msg.data.type === "encryptedMessages") {
          const encryptedMessages = msg.data.encryptedMessages;
          this.broadcaster.broadcast("encryptedMessages", encryptedMessages);
        } else  if (msg && msg.data && msg.data.type === "storeMessages") {
          const messagesToStore = msg.data.messagesToStore;
          this.store.dispatch(new MessageBulkAppend({
            messages: messagesToStore as Message[],
            conversationTarget:  msg.data.target,
            skipSetLoadingToFalse: true
          }));
        } else  if (msg && msg.data && msg.data.type === "deleteMessages") {
          const messagesToDelete = msg.data.messagesToDelete;
          this.store.dispatch(new DeleteMessages({
            messageIds: messagesToDelete.map(v => v.id),
            persistentConversationIds: [msg.data.target],
            nonPersistentConversationIds: []
          }));
          messagesToDelete.forEach(msg => {
            // this.logger.info("[dbservice][deleteMessageFromWorker] ", msg.id, msg);
            this.deleteMessage(msg).subscribe();
          });
        } else  if (msg && msg.data && msg.data.type === "deleteExpiredMessages") {
          const messagesToDelete = msg.data.messagesToDelete;
          this.store.dispatch(new DeleteMessages({
            messageIds: messagesToDelete.map(v => v.id)
          }));
          messagesToDelete.forEach(msg => {
            // this.logger.info("[dbservice][deleteExpiredMessageFromWorker] ", msg.id, msg);
            this.deleteMessage(msg).subscribe();
          });
        } else if (msg && msg.data && msg.data.type === "finishedSync") {
          const finishedSync = Math.floor(Date.now() / 1000).toString();
          localStorage.setItem("lastSyncCompleted", finishedSync);
          // this.logger.info("[IndexedDBWorker] finishedSync -> BGSYNCOMPLETE");
          this.broadcaster.broadcast("BGSYNCOMPLETE");
        }
      });
     } else {
      this.logger.info(`[IndexedDBWorker] Web Workers are not supported in this environment.`);
        // Web Workers are not supported in this environment.
      // You should add a fallback so that your program still executes correctly.
     }
  }


  private isFirefox() {
    return /firefox/i.test(navigator.userAgent.toLowerCase());
  }

  private idbContext(ignoreDiffUsersCheck = false): any {
    if (!this.currentUserEmail) {
      console.warn("[IndexedDBService][idbContext] no currentUserEmail");

      return new Promise((resolve, reject) => {
        this.store.select(getUserProfile).pipe(filter(profile => !!profile), take(1)).subscribe(currentProfile => {
          console.warn("[IndexedDBService][idbContext] currentProfile.user", currentProfile.user.email);
          this.currentUserEmail = currentProfile.user.email;

          this.createIdbContext(ignoreDiffUsersCheck).then(res => {
            resolve(res);
          }).catch(err => {
            reject(err);
          });
        });
      });
    } else {
      return this.createIdbContext(ignoreDiffUsersCheck);
    }
  }

  openDBPromise: any;
  private createIdbContext(ignoreDiffUsersCheck = false): any {
    this.logger.info("[IndexedDBService ][idbContext]", this.dbName, this.dbVersion);
    // console.trace("[IndexedDBService ][idbContext]", this.dbName, this.dbVersion);

    // if (this.openDBPromise) {
    //   return this.openDBPromise;
    // }
    const self = this;
    this.openDBPromise = new Promise((resolve, reject) => {
      // already opened?
      if (this.db) {
        resolve(this.db);
        return;
      }

      openDB(this.dbName, this.dbVersion, {
        upgrade(db, oldVersion, newVersion, transaction) {
          self.logger.info("[IndexedDBService][idbContext][upgrade]", oldVersion, newVersion);

          // for some beta users we need to remove some old storages
          if (oldVersion < 3) {
            self.logger.info("[IndexedDBService][idbContext][upgrade]", "delete old stores");
            try {
              db.deleteObjectStore(MESSAGES_DB_STORE);
              db.deleteObjectStore(ATTACHMENTS_DB_STORE);
              db.deleteObjectStore(CONVERSATIONS_DB_STORE);
              db.deleteObjectStore(CONVERSATION_SETTINGS_DB_STORE);
              db.deleteObjectStore(CONTACTS_DB_STORE);
              db.deleteObjectStore(CURRENT_USER_DB_STORE);
              //
              db.deleteObjectStore(OMEMO_DEVICES_DB_STORE);
              db.deleteObjectStore(OMEMO_WHISPER_DB_STORE);
              db.deleteObjectStore(OMEMO_IDENTITY_KEY_PAIR_DB_STORE);
              db.deleteObjectStore(OMEMO_IDENTITY_KEY_DB_STORE);
              db.deleteObjectStore(OMEMO_PRE_KEY_DB_STORE);
              db.deleteObjectStore(OMEMO_SIGNED_PRE_KEY_DB_STORE);
              db.deleteObjectStore(OMEMO_SESSION_DB_STORE);
            } catch (e) {
              // self.logger.sentryErrorLog("[IndexedDBService][idbContext][upgrade]", "old stores were not found");
              self.logger.info("[IndexedDBService][idbContext][upgrade]", "old stores were not found");
            }
            self.logger.info("[IndexedDBService][idbContext][upgrade]", "delete old stores done");

            // Messages store
            const messagesStore = db.createObjectStore(MESSAGES_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false,
            });
            messagesStore.createIndex(INDEX_BY_TARGET, "convTarget");
            messagesStore.createIndex(INDEX_BY_SORT_ID, "sort_id");
            messagesStore.createIndex(INDEX_BY_TIMESTAMP, "timestamp");
            // messagesStore.createIndex(INDEX_BY_EXPIRY, "expiry");
            messagesStore.createIndex(INDEX_BY_TARGET_AND_TIMESTAMP, ["convTarget", "timestamp"], { unique: false });

            // Attachments store
            db.createObjectStore(ATTACHMENTS_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });

            // Conversations store
            const conversationsStore = db.createObjectStore(CONVERSATIONS_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "Target",
              autoIncrement: false,
            });
            conversationsStore.createIndex(INDEX_BY_TARGET, "Target");

            // Contacts store
            db.createObjectStore(CONTACTS_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });

            // Current users store
            db.createObjectStore(CURRENT_USER_DB_STORE, {
              // The 'jid' property of the object will be the key.
              keyPath: "email",
              autoIncrement: false
            });

            const omemoDevicesStore = db.createObjectStore(OMEMO_DEVICES_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: true
            });
            omemoDevicesStore.createIndex(INDEX_BY_JID, "jid");

            const omemoWhisperStore = db.createObjectStore(OMEMO_WHISPER_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: true
            });
            omemoWhisperStore.createIndex(INDEX_BY_MID, "mid");

            const omemoIdentityStore = db.createObjectStore(OMEMO_IDENTITY_KEY_PAIR_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: true
            });
            omemoIdentityStore.createIndex(INDEX_BY_JID, "jid");

            db.createObjectStore(OMEMO_IDENTITY_KEY_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });

            db.createObjectStore(OMEMO_PRE_KEY_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });

            db.createObjectStore(OMEMO_SIGNED_PRE_KEY_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });

            db.createObjectStore(OMEMO_SESSION_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });
          }

          if (newVersion >= 4 && oldVersion < 4) {
            // Conversation settings store
            const conversationSettingsStore = db.createObjectStore(CONVERSATION_SETTINGS_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "Target",
              autoIncrement: false,
            });
            conversationSettingsStore.createIndex(INDEX_BY_TARGET, "Target");
          }

          if (newVersion >= 5 && oldVersion < 5) {
            const conversationStore = transaction.objectStore(CONVERSATIONS_DB_STORE);
            conversationStore.createIndex(INDEX_BY_TIMESTAMP, "Timestamp");
          }
          if (newVersion >= 6) {
            const messagesStore = transaction.objectStore(MESSAGES_DB_STORE);
            self.logger.info("[IndexedDBService][idbContext][messagesStore]", messagesStore.indexNames);
            if (!messagesStore.indexNames.contains(INDEX_BY_EXPIRY)) {
              messagesStore.createIndex(INDEX_BY_EXPIRY, "expiry");
            }
          }
          // we need to skip 7 due to https://vncproject.vnc.biz/issues/30003052-25663
          if (newVersion >= 8 && oldVersion < 8) {
            self.logger.info("[IndexedDBService][idbContext][upgrade]", "add avatar store");
            db.createObjectStore(AVATAR_DB_STORE, {
              // The 'id' property of the object will be the key.
              keyPath: "id",
              autoIncrement: false
            });
          }
          if (newVersion >= 8) {
            if (!db.objectStoreNames.contains(OMEMO_DECRYPTQUEUE_DB_STORE)) {
              const omemoQueueStore = db.createObjectStore(OMEMO_DECRYPTQUEUE_DB_STORE, {
                keyPath: "id",
                autoIncrement: false
              });
              omemoQueueStore.createIndex(INDEX_BY_TIMESTAMP, "timestamp");
              // messagesStore.createIndex(INDEX_BY_EXPIRY, "expiry");
              omemoQueueStore.createIndex(INDEX_BY_TARGET_AND_TIMESTAMP, ["convTarget", "timestamp"], { unique: false });
            }
            if (!db.objectStoreNames.contains(OMEMO_ERROR_DB_STORE)) {
              const omemoErrorStore = db.createObjectStore(OMEMO_ERROR_DB_STORE, {
                keyPath: "id",
                autoIncrement: false
              });
              omemoErrorStore.createIndex(INDEX_BY_TIMESTAMP, "timestamp");
            }
          }
          if (!db.objectStoreNames.contains(OMEMO_LAST_ACTIVE_DB_STORE)) {
            const omemoLastActiveStore = db.createObjectStore(OMEMO_LAST_ACTIVE_DB_STORE, {
              keyPath: "target",
              autoIncrement: false
            });
            omemoLastActiveStore.createIndex(INDEX_BY_TIMESTAMP, "timestamp");
          }
        },
        blocked() {
          self.logger.info("[IndexedDBService][idbContext][blocked]");
        },
        blocking() {
          self.logger.info("[IndexedDBService][idbContext][blocking]");
        }
      }).then (db => {
        this.db = db;
        self.logger.info("[IndexedDBService][idbContext] DB OPENED, isCheckedForNewUser: ", this.isCheckedForNewUser);

        //fixExpiryIndexWhenRequired

        // if default (old) db name is used
        if (!ignoreDiffUsersCheck && !this.isCheckedForNewUser) {
          self.logger.info("[IndexedDBService][idbContext][isCheckedForNewUser]", "DBREADY2");

          this.openDBPromise = null;
          resolve(db);

          this.isCheckedForNewUser = true;
          this.broadcaster.broadcast("DB_READY");

          // cleear old DBs
          if (!this.isFirefox()) {
            this.clearAllDBsExceptCurrent().subscribe(() => {
              self.logger.info("[IndexedDBService][idbContext] clearAllDBsExceptCurrent", "done");
            });
          }
        } else {
          this.openDBPromise = null;
          resolve(db);
        }

      }).catch(err => {
        this.openDBPromise = null;
        reject(err);
      });
    });

    return this.openDBPromise;
  }

  private close() {
    this.logger.info("[IndexedDBService][close]");
    this.db.close();
  }

  deleteCurrentDatabase(): Observable<any> {
    this.close();
    return this.deleteDatabase(this.dbName);
  }

  deleteDatabase(name?: string): Observable<any> {
    this.logger.info("[IndexedDBService][deleteDatabase]", name || this.dbName);

    const response = new Subject<any>();

    deleteDB(name || this.dbName, {
      blocked() {
        // …
      },
    }).then(res => {
      this.logger.info("[IndexedDBService][deleteDatabase] res: ", res);
      response.next(true);
    }).catch(err => {
      this.logger.error("[IndexedDBService][deleteDatabase]", err);
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  clearIndexedDB(): Promise<void> {
    const request = indexedDB.deleteDatabase(this.dbName);
    return new Promise<void>((resolve, reject) => {
      request.onsuccess = () => {
        resolve();
      };
      request.onerror = (event) => {
        reject();
      };
    });
  }

  clearAllDBsExceptCurrent(): Observable<any> {
    this.logger.info("[IndexedDBService][clearAllDBsExceptCurrent]", this.dbName);

    const response = new Subject<any>();

    (window.indexedDB as any).databases().then(dbs => {
      const dbNamesToRemove = dbs.map(db => db.name).filter(name => name !== this.dbName);

      this.logger.info("[IndexedDBService][clearAllDBsExceptCurrent] dbNamesToRemove", dbNamesToRemove);

      if (dbNamesToRemove.length === 0) {
        response.next(true);
      } else {
        dbNamesToRemove.forEach(name => {
          this.deleteDatabase(name).subscribe(() => {
            this.logger.info("[IndexedDBService][clearAllDBsExceptCurrent] OK", name);
          }, error => {
            this.logger.error("[IndexedDBService][clearAllDBsExceptCurrent]", name, error);
          });
        });

        response.next(true);
      }
    });

    return response.asObservable().pipe(take(1));
  }

  getCurrentUser(): Observable<string> {
    this.logger.info("[IndexedDBService][getCurrentUser]");

    const response = new Subject<string>();

    this.idbContext(true).then(db => {
      db.getAll(CURRENT_USER_DB_STORE).then(users => {
        this.logger.info("[IndexedDBService][getCurrentUser]", users);
        response.next((users && users.length > 0) ? users[users.length - 1].email : null);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchMessagesByTargets(convTargets: string[]): Observable<any> {
    const response = new Subject<any>();

    const getMessages$ = convTargets.map(target => {
      return this.fetchMessagesByTarget(target);
    });

    const messagesResult = {};

    forkJoin(getMessages$).subscribe(rsp => {
      rsp.forEach((messages: any[]) => {
        if (messages && messages.length > 0) {
          messagesResult[messages[0].convTarget] = messages;
        }
      });

      response.next(messagesResult);
    });

   return response.asObservable().pipe(take(1));
  }

  private fetchMessagesByTarget(convTarget: string): Observable<Message[]> {
    const response = new Subject<Message[]>();

    this.idbContext().then(db => {
      db.getAllFromIndex(MESSAGES_DB_STORE, INDEX_BY_TARGET, convTarget).then(msgs => {
        response.next(msgs);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  // Conversations API
  createOrUpdateConversationSetting(settings: any[]): Observable<any> {
    this.logger.info("[IndexedDBService][createOrUpdateConversationSetting]", settings);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATION_SETTINGS_DB_STORE, "readwrite");
      settings.forEach(setting => {
        tx.store.put(setting);
      });
      tx.done.then(() => {
        this.logger.info("[IndexedDBService][createOrUpdateConversationSetting]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][createOrUpdateConversationSetting]", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  createOrUpdateConversation(conversations: Conversation[], membersOwnerAdmins?: RoomMembersOwnerAvatar[]): Observable<any> {
    this.logger.info("[IndexedDBService][createOrUpdateConversation]", conversations, membersOwnerAdmins);

    const t1 = performance.now();

    const response = new Subject<any>();

    const id = Date.now();

    const responseHandler = (event: Event) => {
      const data = event.data;
      if (data.id === id) {
        this.logger.info(`[IndexedDBService][IDBcreateOrUpdateConversation][onmessage]`, data);

        if (data.error) {
          response.error(data.error);
        } else {
          response.next(data.result);
        }

        const t2 = performance.now();
        this.logger.info(`[PERFORMANCE][IndexedDBService] createOrUpdateConversation: took ${t2 - t1} milliseconds.`);

        this.worker.removeEventListener("message", responseHandler);
      }
    };

    this.worker.addEventListener("message", responseHandler);

    this.worker.postMessage({type: "createOrUpdateConversation", id,
        args: {conversations, membersOwnerAdmins}});

    return response.asObservable().pipe(take(1));
  }

  fetchConversationsTop(count: number): Observable<Conversation[]> {
    this.logger.info("[IndexedDBService][fetchConversationsTop]", count);
    const response = new Subject<Conversation[]>();

    const results = [];

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readonly");
      const store = tx.objectStore(CONVERSATIONS_DB_STORE);

      const index = store.index(INDEX_BY_TIMESTAMP);

      return index.openCursor(null, "prev");
    }).then(function processConv(cursor) {
      if (cursor) {
        const conv: Conversation = cursor.value;
        results.push(conv);

        if (results.length == count) {
          return;
        }
        return cursor.continue().then(processConv);
      } else {
        return;
      }
    }).then( () => {
      this.logger.info("[IndexedDBService][fetchConversationsTop]", results);
      response.next(results);
    }).catch(error => {
      response.error(error);
    });

    return response.asObservable().pipe(take(1));
  }

  fetchConversationsBefore(lastUpdatedAt: number, count: number): any {
    this.logger.info("[IndexedDBService][fetchConversationsBefore]",  {lastUpdatedAt, count});
    const response = new Subject<any>();

    const results = [];

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readonly");
      const store = tx.objectStore(CONVERSATIONS_DB_STORE);
      const lowerBound = 0;
      const upperBound = lastUpdatedAt;
      const range = IDBKeyRange.bound(lowerBound, upperBound);
      const index = store.index(INDEX_BY_TIMESTAMP);
      return index.openCursor(range, "prev");
    }).then(function processConversations(cursor) {
      if (cursor) {
        const message: any = cursor.value;
        results.push(message);

        if (results.length == count) {
          return;
        }
        return cursor.continue().then(processConversations);
      } else {
        return;
      }
    }).then(() => {
      this.logger.info("[IndexedDBService][fetchConversationsBefore]", results);
      response.next(results);
    }).catch(error => {
      response.error(error);
    });
    return response.asObservable().pipe(take(1));
  }

  getMaxConversationTimestamp(): Observable<number> {
    this.logger.info("[IndexedDBService][getMaxConversationTimestamp]");
    const response = new Subject<number>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readonly");
      const store = tx.objectStore(CONVERSATIONS_DB_STORE);
      const index = store.index(INDEX_BY_TIMESTAMP);

      let maxTs = null;
      index.openCursor(null, "prev").then((cursor) => {
        if (cursor) {
          maxTs = cursor.value.Timestamp;
          this.logger.info("[IndexedDBService][getMaxConversationTimestamp] CONV", maxTs, cursor.value);
        }
      });

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][getMaxConversationTimestamp]", maxTs);
        response.next(maxTs);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getMaxConversationTimestamp]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchConversationSettings(): Observable<any> {
    this.logger.info("[IndexedDBService][fetchConversationSettings]");
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATION_SETTINGS_DB_STORE, "readonly");
      const store = tx.objectStore(CONVERSATION_SETTINGS_DB_STORE);

      store.getAll().then(result => {
        this.logger.info("[IndexedDBService][fetchConversationSettings]", result);
        response.next(result);
      }).catch(error => {
        this.logger.error("[IndexedDBService][fetchConversationSettings]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deleteConversation(target: string): Observable<any> {
    this.logger.info("[IndexedDBService][deleteConversation]", target);
    return this.deleteConversations([target]);
  }

  deleteConversations(ids: string[]): Observable<any> {
    this.logger.info("[IndexedDBService][deleteConversations]", ids);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");

      ids.forEach((id) => {
        tx.store.delete(id);
        this.deleteMessagesForConversation(id).subscribe();
      });

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][deleteConversations]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][deleteConversations]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  private deleteMessagesForConversation(convId: string): Observable<any> {
    this.logger.info("[IndexedDBService][deleteMessagesForConversation]", convId);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      db.getAllFromIndex(MESSAGES_DB_STORE, INDEX_BY_TARGET, convId).then(msgs => {
        this.logger.info(`[IndexedDBService][deleteMessagesForConversation][${convId}] messages`, msgs);

        const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");

        msgs.forEach(message => {
          // this.logger.info(`[IndexedDBService][deleteMessagesForConversation] deleteMessage`, message.id, message);
          tx.store.delete(message.id);
        });

        tx.done.then(() => {
          this.logger.info(`[IndexedDBService][deleteMessagesForConversation][${convId}]`, "OK");
          response.next(true);
        }).catch(error => {
          this.logger.error(`[IndexedDBService][deleteMessagesForConversation][${convId}]`, error);
          response.error(error);
        });
      });
    });

    return response.asObservable().pipe(take(1));
  }

  updateConversationMembersOwnerAdmins(target: string, members: string[], owner?: string, admins?: string[], has_iom?:boolean): Observable<any> {
    this.logger.info(`[IndexedDBService][updateConversationMembersOwnerAdmins][${target}]`);
    // this.logger.info(`[IndexedDBService][updateConversationMembersOwnerAdmins][${target}]`, members, owner ? owner : "", admins ? admins : "");

    const t1 = performance.now();

    const response = new Subject<any>();

    const id = Date.now();

    const responseHandler = (event: Event) => {
      const data = event.data;
      if (data.id === id) {
        this.logger.info(`[IndexedDBService][updateConversationMembersOwnerAdmins][onmessage]`, data);

        if (data.error) {
          response.error(data.error);
        } else {
          response.next(data.result);
        }

        const t2 = performance.now();
        this.logger.info(`[PERFORMANCE][IndexedDBService] updateConversationMembersOwnerAdmins: took ${t2 - t1} milliseconds.`);

        this.worker.removeEventListener("message", responseHandler);
      }
    };

    this.worker.addEventListener("message", responseHandler);

    this.worker.postMessage({type: "updateConversationMembersOwnerAdmins", id,
        args: {target, members, owner, admins, has_iom}});

    return response.asObservable().pipe(take(1));
  }

  updateConversationContent(target: string, content: string, lastMsgId?: string, historystate?: string): Observable<any> {
    this.logger.info(`[IndexedDBService][updateConversationContent][${target}]`, content, lastMsgId);
    const response = new Subject<any>();
    const id = Date.now();

    const responseHandler = (event: Event) => {
      const data = event.data;
      if (data.id === id) {
        if (data.error) {
          response.error(data.error);
        } else {
          response.next(data.result);
        }
        this.worker.removeEventListener("message", responseHandler);
      }
    };

    this.worker.addEventListener("message", responseHandler);

    this.worker.postMessage({type: "updateConversationContent", id,
        args: {target, content, lastMsgId, historystate}});

    return response.asObservable().pipe(take(1));
  }

  updateConversationConferenceStart(target: string, timestamp: number): Observable<any> {
    this.logger.info(`[IndexedDBService][updateConversationConferenceStart][${target}]`, timestamp);
    const response = new Subject<any>();
    const id = Date.now();

    const responseHandler = (event: Event) => {
      const data = event.data;
      if (data.id === id) {
        if (data.error) {
          response.error(data.error);
        } else {
          response.next(data.result);
        }
        this.worker.removeEventListener("message", responseHandler);
      }
    };

    this.worker.addEventListener("message", responseHandler);

    this.worker.postMessage({type: "updateConversationConferenceStart", id,
        args: {target, timestamp}});

    return response.asObservable().pipe(take(1));
  }

  getConversationMembersOwnerAdmins(target: string): Observable<any[]> {
    this.logger.info(`[IndexedDBService][getConversationMembersOwnerAdmins]`, target);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readwrite");
      const store = tx.objectStore(CONVERSATIONS_DB_STORE);
      const index = store.index(INDEX_BY_TARGET);

      let resp;
      index.get(target).then(existConv => {
        if (existConv) {
          resp = [existConv.members || [], existConv.owner || null, existConv.admins || []];
        } else {
          console.warn("[IndexedDBService][getConversationMembersOwnerAdmins] no conv", target);
        }
      });

        tx.done.then(() => {
          this.logger.info(`[IndexedDBService][getConversationMembersOwnerAdmins][${target}]`, "OK", resp);
          response.next(resp);
        }).catch(error => {
          this.logger.error(`[IndexedDBService][getConversationMembersOwnerAdmins][${target}]`, error);
          response.error(error);
        });
    });

    return response.asObservable().pipe(take(1));
  }

  getConversationFromDB(target: string): Observable<any[]> {
    this.logger.info(`[IndexedDBService][getConversationFromDB]`, target);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONVERSATIONS_DB_STORE, "readonly");
      const store = tx.objectStore(CONVERSATIONS_DB_STORE);
      const index = store.index(INDEX_BY_TARGET);

      const results = [];
      index.get(target).then(existConv => {
        if (existConv) {
          results.push(existConv);
        } else {
          console.warn("[IndexedDBService][getConversationFromDB] no conv", target);
        }
      });

        tx.done.then(() => {
          this.logger.info(`[IndexedDBService][getConversationFromDB][${target}]`, "OK", results);
          response.next(results);
        }).catch(error => {
          this.logger.error(`[IndexedDBService][getConversationFromDB][${target}]`, error);
          response.error(error);
        });
    });

    return response.asObservable().pipe(take(1));
  }


  // Messages API
  createOrUpdateMessages(messages: Message[], convTarget: string) {
    this.logger.info(`[IndexedDBService][createOrUpdateMessages][${convTarget ? convTarget : ""}]`, messages);
    if (!!messages && (messages.length > 0)) {
      const id = Date.now();
      this.worker.postMessage({type: "createOrUpdateMessages", id,
          args: {convTarget, messages}});

    } else {
      this.logger.info("[IndexedDBService][createOrUpdateMessages] skip, no messages to process");
    }
  }

  createOrUpdateDecryptedMessage(message: Message, convTarget: string): Observable<any[]> {
    const response = new Subject<any>();
    this.logger.info(`[IndexedDBService][createOrUpdateDecryptedMessage][${convTarget ? convTarget : ""}]`, message);
    if (!!message) {
      const mid = message.id;
      this.idbContext().then(db => {
        const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");
        const store = tx.objectStore(MESSAGES_DB_STORE);

        store.get(IDBKeyRange.only(mid)).then(existingMsg => {
          const objToSave: any = existingMsg ? { ...existingMsg, ...message } : { ...message };
          this.logger.info(`[IndexedDBService][createOrUpdateDecryptedMessage][${convTarget ? convTarget : ""}] existingMsg `, existingMsg);
          this.logger.info(`[IndexedDBService][createOrUpdateDecryptedMessage][${convTarget ? convTarget : ""}] existingMsg `, objToSave);
          objToSave.convTarget = convTarget;
          if (!!objToSave.cachedContent && (objToSave.cachedContent !== objToSave.body) && ((objToSave.body === "Encrypted message") || (objToSave.body === "Can't decrypt the message - not intended for current device"))) {
            objToSave.body = objToSave.cachedContent;
          }
          if ((objToSave.body === "Encrypted message") || (objToSave.body === "Can't decrypt the message - not intended for current device")) {
            objToSave.body = message.body;
            if (!!message.htmlBody) {
              objToSave.htmlBody = message.htmlBody;
            }
          }
          tx.store.put(objToSave);

          tx.done.then(() => {
            this.logger.info("[IndexedDBService][createOrUpdateDecryptedMessage]", "OK", objToSave);
            response.next(true);
          }).catch(error => {
            this.logger.error("[IndexedDBService][createOrUpdateDecryptedMessage]", error);
            response.error(error);
          });

        }).catch(error => {
          this.logger.error("[IndexedDBService][createOrUpdateDecryptedMessage] error", error);
          response.error(error);
        });
      });

    } else {
      this.logger.info("[IndexedDBService][createOrUpdateDecryptedMessage] skip, no messages to process");
      setTimeout(() => {
        response.next(false);
      }, 1);
    }
    return response.asObservable().pipe(take(1));
  }


  deleteMessage(message: any): Observable<any> {
    this.logger.info("[IndexedDBService][deleteMessage]", message.id);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE, "readwrite");

      tx.store.delete(message.id);

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][deleteMessage]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][deleteMessage]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getMessageById(mid: string): Observable<Message> {
    // this.logger.info("[IndexedDBService][getMessageById]", mid);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(MESSAGES_DB_STORE);
      const store = tx.objectStore(MESSAGES_DB_STORE);

      store.get(IDBKeyRange.only(mid)).then(msg => {
        // this.logger.info("[IndexedDBService][getMessageById] Ok", mid, msg);
        response.next(msg);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getMessageById] error", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchMessages(convTarget: string, lastMessageTimestamp: number, count: number): Observable<Message[]> {
    this.logger.info("[IndexedDBService][fetchMessages]", convTarget, lastMessageTimestamp, count);

    const t1 = performance.now();

    const response = new Subject<Message[]>();

    const id = Date.now();

    const responseHandler = (event: Event) => {
      const data = event.data;
      if (data.id === id) {
        this.logger.info(`[IndexedDBService][fetchMessages][onmessage]`, data);

        const t2 = performance.now();

        if (data.error) {
          response.error(data.error);
        } else {
          response.next(data.results);
        }

        const t3 = performance.now();
        this.logger.info(`[PERFORMANCE][IndexedDBService] fetchMessages: took ${Math.ceil(t3 - t1)} ${Math.ceil(t2 - t1)} milliseconds.`);

        this.worker.removeEventListener("message", responseHandler);
      }
    };

    this.worker.addEventListener("message", responseHandler);
    this.worker.postMessage({type: "fetchMessages", id,
                    args: {convTarget, lastMessageTimestamp, count}});


    // this._fetchMessages(convTarget, lastMessageTimestamp, count).then((results: any) => {
    //   const t2 = performance.now();

    //   response.next(results);

    //   const t3 = performance.now();
    //   this.logger.info(`[PERFORMANCE][IndexedDBService] fetchMessages: took ${Math.ceil(t3 - t1)} ${Math.ceil(t2 - t1)} milliseconds.`);

    // }).catch((error: any) => {
    //   response.error(error);
    // });

    return response.asObservable().pipe(take(1));
  }

  // private _fetchMessages(convTarget: string, lastMessageTimestamp: number, count: number): any {
  //   this.logger.info("[IndexedDBWorker][fetchMessages]",  {convTarget, lastMessageTimestamp, count});

  //   return new Promise((resolve, reject) => {
  //     let results = [];

  //     this.idbContext().then(db => {
  //       const tx = db.transaction(MESSAGES_DB_STORE, "readonly");
  //       const store = tx.objectStore(MESSAGES_DB_STORE);
  //       const lowerBound = [convTarget, 0];
  //       const upperBound = [convTarget, lastMessageTimestamp];
  //       const range = IDBKeyRange.bound(lowerBound, upperBound);
  //       const index = store.index(INDEX_BY_TARGET_AND_TIMESTAMP);

  //       return index.openCursor(range, "prev");
  //     }).then(function processMessages(cursor) {
  //       if (cursor) {
  //         const message: any = cursor.value;
  //         results.push(message);

  //         if (results.length == count) {
  //           return;
  //         }
  //         return cursor.continue().then(processMessages);
  //       } else {
  //         return;
  //       }
  //     }).then(function () {
  //       this.logger.info("[IndexedDBWorker][fetchMessages]", results);
  //       resolve(results);
  //     }).catch(error => {
  //       reject(error);
  //     });
  //   });
  // }

  getAttachmentUrl(serverUrl: string): Observable<string> {
    this.logger.info("[IndexedDBService][getAttachmentUrl]", serverUrl);
    const response = new BehaviorSubject<string>(null);
    response.next(serverUrl);
    return response.asObservable().pipe(filter(u => !!u), take(1));
  }

  createOrUpdateContacts(contacts: Contact[]): Observable<any> {
    this.logger.info("[IndexedDBService][createOrUpdateContacts]", contacts);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONTACTS_DB_STORE, "readwrite");
      contacts.forEach(contact => {
        tx.store.put({
          ...contact
        });
      });

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][createOrUpdateContacts]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][createOrUpdateContacts]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchContacts(): Observable<any> {
    this.logger.info("[IndexedDBService][fetchContacts]");
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONTACTS_DB_STORE, "readonly");

      tx.store.getAll().then(result => {
        this.logger.info("[IndexedDBService][fetchContacts]", result);
        response.next(result);
      }).catch(error => {
        this.logger.error("[IndexedDBService][fetchContacts]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  deleteContacts(ids: any[]): Observable<any> {
    this.logger.info("[IndexedDBService][deleteContacts]");
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(CONTACTS_DB_STORE, "readwrite");

      ids.forEach((id) => {
        tx.store.delete(id);
      });

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][deleteContacts]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][deleteContacts]", error);
        response.error(error);
      });

    });

    return response.asObservable().pipe(take(1));
  }


  // OMEMO
  //

  getDevices(jid: string): Observable<any[]> {
    this.logger.info("[IndexedDBService][getDevices]", jid);

    const response = new Subject<any[]>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_DEVICES_DB_STORE);
      const index = tx.store.index(INDEX_BY_JID);

      index.getAll(jid).then(devices => {
        devices = devices.map(d => {
          return {id: d.deviceId, label: d.label};
        });
        this.logger.info("[IndexedDBService][getDevices]", "Ok", jid, devices);
        response.next(devices);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getDevices]", jid, error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  hasDevices(jid: string): Observable<boolean> {
    this.logger.info("[IndexedDBService][hasDevices]", jid);

    const response = new Subject<any>();

    this.getDevices(jid).subscribe(devices => {
      const has = devices.length > 0;
      this.logger.info("[IndexedDBService][hasDevices]", jid, has);
      response.next(has);
    });

    return response.asObservable().pipe(take(1));
  }

  storeDevices(jid: string, devices: any[]): Observable<any> {
    this.logger.info("[IndexedDBService][storeDevices]", jid, devices);

    // const newDeviceIds = devices.map(d => d.id);
    let newDeviceIds = [];
    devices.forEach(d => {
      // this.logger.info("[IndexedDBService][storeDevices] mapDeviceId", d.id, typeof d.id);
      if (d && typeof d.id === "string") {
        // deviceIds are sometimes provided as string, sometimes as number - hence the
        // check if device already exists failed in some cases
        // forcing one type did not help - hence adding both number and string to the array
        newDeviceIds.push(parseInt(d.id, 10));
      }
      newDeviceIds.push(d.id);
    });

    this.logger.info("[IndexedDBService][storeDevicesIds]", jid, devices, newDeviceIds);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_DEVICES_DB_STORE, "readwrite");
      const index = tx.store.index(INDEX_BY_JID);

      // 1. delete prev ddvices
      // index.getAllKeys(jid).then(keys => {
      index.getAll(jid).then(olddevices => {
        this.logger.info("[IndexedDBService][storeDevices] store-" + jid + " keys", olddevices, jid, newDeviceIds);

        // keys.forEach(k => {
        //  tx.store.delete(k);
        // });
        let existingDeviceIds = [];
        olddevices.forEach(od => {
          // this.logger.info("[IndexedDBService][storeDevices] store-" + jid + " olddevice ", od);
          if ((newDeviceIds.length > 0) && (newDeviceIds[0] !== undefined)) {

            if (newDeviceIds.indexOf(od.deviceId) === -1) {
              this.logger.info("[IndexedDBService][storeDevices] store-" + jid + " mayberemove ", newDeviceIds.indexOf(+od.deviceId));
              this.logger.info("[IndexedDBService][storeDevices] store-" + jid + " removing ", od);
              tx.store.delete(od.id);
              this.storeOmemoError({ err: "deleting known device", old: JSON.stringify(od)}, {body: "none", id: od.id});
            } else {
              this.logger.info("[IndexedDBService][storeDevices] store-" + jid + " keep ", od);
              existingDeviceIds.push(od.deviceId);
            }
          }
        });

        this.logger.info("[IndexedDBService][storeDevices] store-" + jid + " keepall ", existingDeviceIds);

        // 2. add new devices
        devices.forEach(d => {
          if (d && existingDeviceIds.indexOf(+d.id) === -1) {
            this.logger.info("[IndexedDBService][storeDevices] store-" + jid + " adding ", jid, d);
            const stored = Date.now();
            tx.store.put({deviceId: +d.id, jid, label: d.label, stored: stored});
          }
        });

        tx.done.then(() => {
          this.logger.info("[IndexedDBService][storeDevices]", "OK", jid);
          response.next(true);
        }).catch(error => {
          this.logger.error("[IndexedDBService][storeDevices]", jid, error);
          response.error(error);
        });
      }).catch(error => {
        this.logger.error("[IndexedDBService][getDevices]", jid, error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getLocalDevice(): Observable<any> {
    this.logger.info("[IndexedDBService][getLocalDevice]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_DEVICES_DB_STORE);
      const index = tx.store.index(INDEX_BY_JID);

      index.getKey("local").then(key => {
        this.logger.info("[IndexedDBService][getLocalDevice] key", key);
        if (!key) {
          response.next(undefined);
        } else {
          tx.store.get(key).then(localDevice => {
            this.logger.info("[IndexedDBService][getLocalDevice] localDevice", localDevice);
            response.next({id: localDevice.deviceId, label: localDevice.label});
          }).catch(error => {
            this.logger.error("[IndexedDBService][getLocalDevice]", error);
            response.error(error);
          });
        }
      }).catch(error => {
        this.logger.error("[IndexedDBService][getLocalDevice]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  storeLocalDevice(device: any): Observable<any> {
    this.logger.info("[IndexedDBService][storeLocalDevice]", device);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_DEVICES_DB_STORE, "readwrite");

      tx.store.put({deviceId: device.id, jid: "local", label: device.label});

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeLocalDevice]", "OK");
        this.broadcaster.broadcast("UPDATED_LOCAL_OMEMO_DEVICE", device);
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeLocalDevice]", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }
  //
  storeWhisper(jid: string, id: string, whisper: any): Observable<any> {
    this.logger.info("[IndexedDBService][storeWhisper]", jid, id, whisper);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_WHISPER_DB_STORE, "readwrite");

      tx.store.put({mid: id, jid, whisper});

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeWhisper]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeWhisper]", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  getWhisper(jid: string, id: string): Observable<any> {
    this.logger.info("[IndexedDBService][getWhisper]", jid, id);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_WHISPER_DB_STORE);
      const index = tx.store.index(INDEX_BY_MID);

      index.getAll(id).then((whispers: any) => {
        if (!whispers || whispers.length === 0) {
          response.next(undefined);
        } else {
          whispers.forEach((wh: any) => {
            if (wh.jid === jid) {
              this.logger.info("[IndexedDBService][getWhisper] res: ", jid, id, wh.whisper);
              response.next(wh.whisper);
            }
          });
        }
      }).catch(error => {
        this.logger.error("[IndexedDBService][getLocalDeviceId]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }
  //
  getIdentityKeyPair(): Observable<any> {
    this.logger.info("[IndexedDBService][omemo][getIdentityKeyPair]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_IDENTITY_KEY_PAIR_DB_STORE);
      const index = tx.store.index(INDEX_BY_JID);

      index.getKey("local").then(key => {
        this.logger.info("[IndexedDBService][omemo][getIdentityKeyPair] keys", key);
        if (!key) {
          response.next(undefined);
        } else {
          tx.store.get(key).then(keyPair => {
            response.next({pubKey: keyPair.pubKey, privKey: keyPair.privKey});
          }).catch(error => {
            this.logger.error("[IndexedDBService][getIdentityKeyPair]", error);
            response.error(error);
          });
        }
      }).catch(error => {
        this.logger.error("[IndexedDBService][getIdentityKeyPair]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  storeIdentityKeyPair(keyPair: any): Observable<any> {
    this.logger.info("[IndexedDBService][omemo][storeIdentityKeyPair]");

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_IDENTITY_KEY_PAIR_DB_STORE, "readwrite");

      tx.store.put({...keyPair, jid: "local"});

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeIdentityKeyPair]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeIdentityKeyPair]", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  getIdentityKey(identityId: string): Observable<any> {
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_IDENTITY_KEY_DB_STORE);
      tx.store.get(identityId).then(identity => {
        this.logger.info("[IndexedDBService][omemo][getIdentityKey]", identity, "Ok");
        response.next(identity ? identity.key : undefined);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getIdentityKey]", identityId, error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  storeIdentityKey(identityId: string, identityKey: any): Observable<any> {
    this.logger.info("[IndexedDBService][omemo][storeIdentityKey]", identityId);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_IDENTITY_KEY_DB_STORE, "readwrite");

      tx.store.put({id: identityId, key: identityKey});

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeIdentityKey]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeIdentityKey]", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  getPreKey(keyId: string): Observable<any> {
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_PRE_KEY_DB_STORE);
      tx.store.get(keyId).then(preKey => {
        this.logger.info("[IndexedDBService][omemo][getPreKey]", keyId, "Ok");
        response.next(preKey ? {pubKey: preKey.pubKey, privKey: preKey.privKey} : undefined);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getPreKey]", keyId, error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  storePreKey(keyId: string, preKeyPair: any): Observable<any> {
    this.logger.info("[IndexedDBService][omemo][storePreKey]", keyId);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_PRE_KEY_DB_STORE, "readwrite");

      tx.store.put({id: keyId, ...preKeyPair});

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storePreKey]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storePreKey]", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }
  // removePreKey(keyId: string): Observable<any> {
  //   return null;
  // }
  //
  getSignedPreKey(keyId: string): Observable<any> {
    this.logger.info("[IndexedDBService][omemo][getSignedPreKey]", keyId);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_SIGNED_PRE_KEY_DB_STORE);
      tx.store.get(keyId).then(preKey => {
        this.logger.info("[IndexedDBService][omemo][getSignedPreKey]", "Ok");
        response.next(preKey ? {pubKey: preKey.pubKey, privKey: preKey.privKey} : undefined);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getSignedPreKey]", error);
        this.logger.sentryErrorLog("[IndexedDBService][getSignedPreKey]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  storeSignedPreKey(keyId: string, signedPreKeyPair: any): Observable<any> {
    this.logger.info("[IndexedDBService][omemo][storeSignedPreKey]", keyId);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_SIGNED_PRE_KEY_DB_STORE, "readwrite");

      tx.store.put({id: keyId, ...signedPreKeyPair});

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeSignedPreKey]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeSignedPreKey]", error);
        this.logger.sentryErrorLog("[IndexedDBService][storeSignedPreKey]", error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  removeSignedPreKey(keyId: string): Observable<any> {
    this.logger.info("[IndexedDBService][removeSignedPreKey]", keyId);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_SIGNED_PRE_KEY_DB_STORE);

      tx.store.delete(keyId);

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][omemo][removeSignedPreKey]", "Ok");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][removeSignedPreKey]", error);
        this.logger.sentryErrorLog("[IndexedDBService][removeSignedPreKey]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getAllSessions(): Observable<any> {

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_SESSION_DB_STORE);
      tx.store.getAll().then(sessions => {
        response.next(sessions);
      }).catch(error => {
        this.logger.error("[IndexedDBService][omemo][getAllSessions]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getSession(address: string): Observable<any> {
    this.logger.info("[IndexedDBService][getSession][loadOmemoSession]", address);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_SESSION_DB_STORE);
      tx.store.get(address).then(session => {
        this.logger.info("[IndexedDBService][loadOmemoSession][getSession]", address, "Ok", session);
        response.next(session ? session.session : undefined);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getSession]", address, error);
        this.logger.sentryErrorLog("[IndexedDBService][getSession]", address, error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  storeSession(address: string, session: any): Observable<any> {
    this.logger.info("[IndexedDBService][omemo][storeOMEMOSession]", address, session);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_SESSION_DB_STORE, "readwrite");

      tx.store.put({id: address, session});

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeSession]", "OK", address);
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeSession]", address, error);
        this.logger.sentryErrorLog("[IndexedDBService][storeSession]", address, error);
        response.error(error);
      });
    });
    return response.asObservable().pipe(take(1));
  }
  // removeSession(address: string): Observable<any> {
  //   return null;
  // }
  // removeAllSessions(prefix: string): Observable<any> {
  //   return null;
  // }


  // Avatar API
  storeAvatar(avatarB64Url: string, convTarget: string) {
    const response = new Subject<any>();
    this.logger.info("[IndexedDBService][storeAvatar]", convTarget);

      this.idbContext().then(db => {
        const tx = db.transaction(AVATAR_DB_STORE, "readwrite");

        tx.store.put({ id: convTarget, data: avatarB64Url, updated: Date.now() });

        tx.done.then(() => {
          this.logger.info("[IndexedDBService][storeAvatar]", "OK");
          response.next(true);
        }).catch(error => {
          this.logger.error("[IndexedDBService][storeAvatar]", error);
          response.error(error);
        });
      });

    return response.asObservable().pipe(take(1));
  }

  deleteAvatar(convTarget: string): Observable<any> {
    this.logger.info("[IndexedDBService][deleteAvatar]", convTarget);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(AVATAR_DB_STORE, "readwrite");

      tx.store.delete(convTarget);

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][deleteAvatar]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][deleteAvatar]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getAvatarByBare(convTarget: string): Observable<any> {
    // this.logger.info("[IndexedDBService][getMessageById]", mid);

    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(AVATAR_DB_STORE);
      const store = tx.objectStore(AVATAR_DB_STORE);

      store.get(IDBKeyRange.only(convTarget)).then(data => {
        // this.logger.info("[IndexedDBService][getMessageById] Ok", mid, msg);
        response.next(data);
      }).catch(error => {
        this.logger.error("[IndexedDBService][getAvatarByBare] error", error);
        response.next(null);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchAllAvatarFromDatabase(): Observable<any[]> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(AVATAR_DB_STORE);
      const store = tx.objectStore(AVATAR_DB_STORE);
      tx.store.getAll().then(avatars => {
        console.log("[IndexedDBService][fetchAllAvatarFromDatabase] Ok", avatars);
        response.next(avatars);
      }).catch(error => {
        console.error("[IndexedDBService][fetchAllAvatarFromDatabase] error", error);
        response.next([]);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  storeAttachment(pendingMsgToStore: any) {
    const response = new Subject<any>();
    this.logger.info("[IndexedDBService][storeAttachment]", pendingMsgToStore);

      this.idbContext().then(db => {
        const tx = db.transaction(ATTACHMENTS_DB_STORE, "readwrite");

        tx.store.put(pendingMsgToStore);

        tx.done.then(() => {
          this.logger.info("[IndexedDBService][storeAttachment]", "OK");
          response.next(true);
        }).catch(error => {
          this.logger.error("[IndexedDBService][storeAttachment]", error);
          response.error(error);
        });
      });

    return response.asObservable().pipe(take(1));
  }

  fetchAllPendingAttachments(): Observable<any[]> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(ATTACHMENTS_DB_STORE);
      const store = tx.objectStore(ATTACHMENTS_DB_STORE);
      tx.store.getAll().then(attachmentMessages => {
        console.log("[IndexedDBService][fetchAllPendingAttachments] Ok", attachmentMessages);
        response.next(attachmentMessages);
      }).catch(error => {
        console.error("[IndexedDBService][fetchAllPendingAttachments] error", error);
        response.next([]);
      });
    });
    return response.asObservable().pipe(take(1));
  }

  fetchPendingAttachmentById(id): Observable<any> {
    const response = new Subject<any>();
    this.idbContext().then(db => {
      const tx = db.transaction(ATTACHMENTS_DB_STORE);
      const store = tx.objectStore(ATTACHMENTS_DB_STORE);
      tx.store.get(IDBKeyRange.only(id)).then(attachmentMessage => {
        console.log("[IndexedDBService][fetchAllPendingAttachments] Ok", attachmentMessage);
        response.next(attachmentMessage);
      }).catch(error => {
        console.error("[IndexedDBService][fetchAllPendingAttachments] error", error);
        response.next({});
      });
    });
    return response.asObservable().pipe(take(1));
  }

  deletePendingAttachment(id: string): Observable<any> {
    this.logger.info("[IndexedDBService][deletePendingAttachment]", id);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(ATTACHMENTS_DB_STORE, "readwrite");

      tx.store.delete(id);

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][deletePendingAttachment]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][deletePendingAttachment]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  fetchMessageFromDecryptQueueTop(): Observable<any> {
    this.logger.info("[IndexedDBService][fetchMessageFromDecryptQueueTop]");
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_DECRYPTQUEUE_DB_STORE, "readonly");
      const store = tx.objectStore(OMEMO_DECRYPTQUEUE_DB_STORE);
      const index = store.index(INDEX_BY_TIMESTAMP);
      let resp;
      index.getAll(null, 1).then(msg => {
        resp = msg;
      });

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][fetchMessageFromDecryptQueueTop]", "OK", resp);
        response.next(resp);
      }).catch(error => {
        this.logger.error("[IndexedDBService][fetchMessageFromDecryptQueueTop]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  removeMessageFromDecryptQueue(message: any): Observable<any> {
    this.logger.info("[IndexedDBService][removeMessageFromDecryptQueue]", message.id);
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_DECRYPTQUEUE_DB_STORE, "readwrite");

      tx.store.delete(message.id);

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][removeMessageFromDecryptQueue]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][removeMessageFromDecryptQueue]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }


  addMessageToDecryptQueue(message: Message): Observable<any[]> {
    const response = new Subject<any>();
    this.logger.info(`[IndexedDBService][addMessageToDecryptQueue]`, message);
    if (!!message) {
      const mid = message.id;
      this.idbContext().then(db => {
        const tx = db.transaction(OMEMO_DECRYPTQUEUE_DB_STORE, "readwrite");
        const store = tx.objectStore(OMEMO_DECRYPTQUEUE_DB_STORE);

        store.get(IDBKeyRange.only(mid)).then(existingMsg => {
          let msgToStore = { ...message };
          if (!!existingMsg) {
            this.logger.info("[IndexedDBService][addMessageToDecryptQueue]", "alreadyExisist", message);
          } else {
            if ((!message.timestamp) && (!!message.stamp && !!message.stamp.stamp)) {
              msgToStore["timestamp"] = (+message.stamp.stamp) * 1000;
            }
            if (!msgToStore.timestamp) {
              msgToStore["timestamp"] = Date.now();
            }
            tx.store.put(msgToStore);
          }

          tx.done.then(() => {
            this.logger.info("[IndexedDBService][addMessageToDecryptQueue]", "OK", message);
            response.next(true);
          }).catch(error => {
            this.logger.error("[IndexedDBService][addMessageToDecryptQueue]", error);
            response.error(error);
          });

        }).catch(error => {
          this.logger.error("[IndexedDBService][addMessageToDecryptQueue] error", error);
          response.error(error);
        });
      });

    } else {
      this.logger.info("[IndexedDBService][addMessageToDecryptQueue] skip, no messages to process");
      setTimeout(() => {
        response.next(false);
      }, 1);
    }
    return response.asObservable().pipe(take(1));
  }

  storeOmemoError(error: any, message: Message): Observable<any> {
    const response = new Subject<any>();
    this.logger.info("[IndexedDBService][storeOmemoError]", error, message);

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_ERROR_DB_STORE, "readwrite");
      const error2store = {
        timestamp: Date.now(),
        error: error,
        id: message.id,
        message: message
      };

      tx.store.put(error2store);

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeOmemoError]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeOmemoError]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  cleanupOmemoErrors(): Observable<any> {
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_ERROR_DB_STORE, "readwrite");
      const store = tx.objectStore(OMEMO_ERROR_DB_STORE);
      const index = store.index(INDEX_BY_TIMESTAMP);
      const upperBound = Date.now() - 604800; // 1 week
      const range = IDBKeyRange.upperBound(upperBound);

      db.getAllFromIndex(OMEMO_ERROR_DB_STORE, INDEX_BY_TIMESTAMP, range).then(oldErrors => {
        if (!!oldErrors && oldErrors.length > 0) {
          this.logger.info("[IndexedDBService][oldOmemoErrors]", oldErrors);
          const oldErrorIds = oldErrors.map(e => e.id);
          this.logger.info("[IndexedDBService][oldOmemoErrors ids]", oldErrorIds);
          this.deleteOmemoErrors(oldErrorIds);
        }
        tx.done.then(() => {
          this.logger.info("[IndexedDBService][storeOmemoError]", "OK");
          response.next(true);
        }).catch(error => {
          this.logger.error("[IndexedDBService][storeOmemoError]", error);
          response.error(error);
        });
      });

    });

    return response.asObservable().pipe(take(1));

  }

  deleteOmemoErrors(ids: any[]): Observable<any> {
    const response = new Subject<any>();

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_ERROR_DB_STORE, "readwrite");
      if (!!ids && (ids.length > 0)) {
        ids.forEach(id => {
          this.logger.info("[IndexedDBService][oldOmemoErrors delete]", id);
          tx.store.delete(id);
        });
      }
      tx.done.then(() => {
        this.logger.info("[IndexedDBService][storeOmemoError]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][storeOmemoError]", error);
        response.error(error);
      });

    });
    return response.asObservable().pipe(take(1));

  }

  setLastOmemoActiveTS(target: string): Observable<any> {
    const response = new Subject<any>();
    this.logger.info("[IndexedDBService][setLastOmemoActiveTS]", target);

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_LAST_ACTIVE_DB_STORE, "readwrite");
      const record2store = {
        timestamp: Date.now(),
        target: target
      };

      tx.store.put(record2store);

      tx.done.then(() => {
        this.logger.info("[IndexedDBService][setLastOmemoActiveTS]", "OK");
        response.next(true);
      }).catch(error => {
        this.logger.error("[IndexedDBService][setLastOmemoActiveTS]", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

  getLastOmemoActiveTS(target: string): Observable<any> {
    const response = new Subject<any>();
    this.logger.info("[IndexedDBService][getLastOmemoActiveTS]", target);

    this.idbContext().then(db => {
      const tx = db.transaction(OMEMO_LAST_ACTIVE_DB_STORE);
      const store = tx.objectStore(OMEMO_LAST_ACTIVE_DB_STORE);

      store.get(IDBKeyRange.only(target)).then(res => {
        this.logger.info("[IndexedDBService][getLastOmemoActiveTS] Ok", target, res);
        if (!!res && !!res.timestamp) {
          response.next(res.timestamp);
        } else {
          response.next(0);
        }
      }).catch(error => {
        this.logger.error("[IndexedDBService][getLastOmemoActiveTS] error", error);
        response.error(error);
      });
    });

    return response.asObservable().pipe(take(1));
  }

}
