import {AfterViewInit, ChangeDetectorRef, ElementRef, Component, OnInit, SecurityContext, ViewChild, ViewEncapsulation} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {forkJoin, noop, Observable, of, Subject} from "rxjs";
import { CommonUtil } from "app/talk/utils/common.util";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Channel } from "../models/channel.model";
import { ChannelRepository } from "../repository/channel.repository";
import {delay, filter, catchError, map, take, takeUntil, switchMap} from "rxjs/operators";
import { ConversationRepository } from "../../talk/repositories/conversation.repository";
import { Topic } from "../models/topic.model";
import { SendTopicLinkComponent } from "../send-topic-link/send-topic-link.component";
import { ChannelService } from "../channel.service";
import {TopicCommentScroll} from "../channel-detail/channel-detail.component";
import { Broadcaster } from "../../talk/shared/providers";
import { ChannelSnackbarService } from "../channel-snackbar.service";
import { SnackbarType } from "../models/snackbar.model";
import {EMPTY_STATE_TYPE} from "../../shared/models/user-config.model";
import {DEFAULT_AVATAR, Utils} from "../../common";
import {BehaviorSubject} from "rxjs";
import {FileActionsService} from "../../talk/services/file-actions.service";
import {ToastService} from "../../shared/services/toast.service";
import {File} from "../models/file.model";
import {BroadcastKeys, ConstantsUtil} from "../../talk/utils/constants.util";
import format from "date-fns/format";
import {SmartLinkActions, SmartLinkService} from "../smart-link.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { environment } from "app/environments/environment";
import {ViewTypeEnum} from "../../shared/components/related-topics/related-topics-list/related-topics-list.component";
import {RelatedTopicsService} from "../../shared/components/related-topics/related-topics.service";
import { RemarkService } from "../remark.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {EmbedVideoService} from "ngx-embed-video";
import { StickyNoteService } from "../sticky-note.service";
import {MentionService} from "../mention.service";
import {SmartObject, SmartObjectActions, SmartObjectsService} from "../smart-objects/smart-objects.service";
import {KeywordService} from "../keyword.service";
import {ChannelDetailIconName} from "../channel-detail/channel-detail-icon/channel-detail-icon.component";
import { CommonService } from "app/shared/providers";
import { DatetimeService } from "app/talk/services/datetime.service";
import enUS from "date-fns/locale/en-US";
import de from "date-fns/locale/de";
import { ChannelDetailComponentService } from "../channel-detail/channel-detail.component.service";
import { Cordova } from "sentry-cordova/dist/js/integrations";
import { LoggerService } from "app/shared/services/logger.service";
import { ElectronService } from "app/shared/providers/electron.service";
import { TopicFilterComponentService } from "../channel-detail/topic-filter/topic-filter.component.service";
import { Attachment } from "../models/attachment.model";
import { ConfirmationChannelComponent } from "../confirmation-channel/confirmation-channel.component";
import { ArchiveWindowComponent } from "app/talk/archive-window/archive-window.component";
import { TrashWindowComponent } from "app/talk/trash-window/trash-window.component";
import { ArchiveWindowService } from "app/talk/archive-window/archive-window.service";
declare var Mark: any;


export const TOPIC_COMMENTS_LOADED = "TOPIC_COMMENTS_LOADED";
export const TOPIC_FILES_ADDED = "TOPIC_FILES_ADDED";
export const TOPIC_FILE_REMOVED = "TOPIC_FILES_REMOVED";

@Component({
  selector: "vp-topic-detail",
  templateUrl: "./topic-detail.component.html",
  styleUrls: ["./topic-detail.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class TopicDetailComponent implements OnInit, AfterViewInit {
  topic: any;
  channelId: any;
  topicId: any;
  iomChannel: boolean;
  jid: string = "";
  heroAttachments: any = [];
  selectedChannel: Channel;
  focusToCommentArea = false;
  focusToRelatedTopicsSection = false;
  isUploadRunning = false;
  totalFilesUploading = 0;
  showSuccessBanner = false;
  private isAlive$ = new Subject<boolean>();
  private uploadedFiles = [];
  currentHighlightedIndex = 1;
  totalHighLights = 0;
  scrollData = {};
  emptyStateTypeNoAccess = EMPTY_STATE_TYPE.BUTTON;
  noAccess = false;
  defaultAvatar = DEFAULT_AVATAR;
  private topicBodyInstance: any;
  private channelInfoInstance: any;
  private topicTitleInstance: any;
  private isMarkedEarlier = false;
  $topicFiles: BehaviorSubject<File[]> = new BehaviorSubject<File[]>([]);
  $previewAlreadyOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  $fetchingMoreFiles: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  topicFilesInfo = {loading: false, loaded: true, totalCount: 0};
  topicFilesLength = 0;
  showUnarchivedBanner = false;
  isMobileScreen: boolean = false;
  commentsLoading = false;
  $showGoToParentBanner = new BehaviorSubject<boolean>(false);
  parentChannelId = "";
  parentTopicId = "";
  selfTopicId : string = "";
  bannerLabel: any = "";
  imageWidthUpdated = false;
  imgUpdateLog: any = {};
  descriptionImagesLength = -1;
  maxRetryCount = 50;
  retryCount = 0;
  isOnIOS: boolean = CommonUtil.isOnIOS();
  isCordovaApp: boolean = environment.isCordova;
  relatedTopicView = ViewTypeEnum;
  remarks = [];
  lang = "en";
  stickyNotes = [];
  shouldShowDesc = true;
  video: any = null;
  embeddedVideoLink = "";
  sanitizedEmbeddedVideoLink: SafeResourceUrl = "";
  embeddedVideoHTML: string | null = "";
  defaultThumbnail: any = null;
  videoPlayState: "play" | "paused" = "play";
  @ViewChild("mobileTopicVideoPlayer") mobileTopicVideoPlayer: any;
  sizeLimitForNotUseThumbnailURL = 1024;
  emptyStateBackground: any = "";
  channelDetailIconName = ChannelDetailIconName;
  topicURL: string = "";
  currentUser: any;
  channel: any = {
    archived: false,
    deleted: false,
  };
  topicRestored = false;
  topicUnarchived = false;
  restoreBannerLabel = "TOPIC_IS_RESTORED";
  bannerMsg = "DELETED_TOPIC";
  bannerActionLabel = "RESTORE";
  ipcRenderer: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private channelSnackBarService: ChannelSnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    public matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private channelService: ChannelService,
    private conversationRepo: ConversationRepository,
    private channelRepository: ChannelRepository,
    private fileActionsService: FileActionsService,
    private toastService: ToastService,
    private _translate: TranslateService,
    private _smartLinkService: SmartLinkService,
    private broadcaster: Broadcaster,
    private breakpointObserver: BreakpointObserver,
    private _remarksService: RemarkService,
    private sanitizer: DomSanitizer,
    private commonService: CommonService,
    private datetimeService: DatetimeService,
    private embedService: EmbedVideoService,
    private _stickyNoteService : StickyNoteService,
    private relatedTopicsService: RelatedTopicsService,
    private _mentionService: MentionService,
    private _smartObjectsService: SmartObjectsService,
    private changeDetectionRef: ChangeDetectorRef,
    private _keywordService: KeywordService,
    public channelDetailService: ChannelDetailComponentService,
    private logger: LoggerService,
    private electronService: ElectronService,
    private elementRef: ElementRef,
    private archiveWindowServifce: ArchiveWindowService,
    private _topicFilterService: TopicFilterComponentService,
    ) {
    route.queryParams.pipe(takeUntil(this.isAlive$)).subscribe(q => {
      if (q.parentChannelId && q.parentTopicId) {
        this.$showGoToParentBanner.next(true);
        this.parentChannelId = q.parentChannelId;
        this.parentTopicId = q.parentTopicId;
      }
    });
    this.currentUser = JSON.parse(localStorage.getItem("userConfigFromServer"));
    let currentUrl = CommonUtil.getBaseOriginUrl();
    this.topicURL = `${currentUrl}${this.router.url}`;
  }


  ngOnInit(): void {
    const currentTheme = localStorage.getItem(ConstantsUtil.THEME) || environment.theme;
    if (currentTheme === "hin") {
      this.emptyStateBackground = {"background": "url(" + CommonUtil.getFullUrl("/assets/hin_punkte-01.svg") + ") no-repeat center center"};
    } else {
      this.emptyStateBackground = {"background": "url(" + CommonUtil.getFullUrl("/assets/talk-background-openedchat.jpg") + ")"
      };
    }
    this.commonService.currentLanguage.pipe(takeUntil(this.isAlive$)).subscribe((lang: string) => {
      this.lang = lang;
    });
    this.channelRepository
      .getChannelUserJid()
      .pipe(takeUntil(this.isAlive$))
      .subscribe((jid) => {
        if(!!jid){
          this.jid = jid;
        }
      });
    this.broadcaster.on<any>("topic_related")
      .pipe(takeUntil(this.isAlive$)).subscribe((data) => {
        this.topic.related_topics.push(data.topic);
        this.changeDetectionRef.markForCheck();
      });
      // removed_relation
      this.broadcaster.on<any>("removed_relation")
      .pipe(takeUntil(this.isAlive$)).subscribe((ids) => {
        this.topic.related_topics = this.topic.related_topics.filter(topics => topics?.id != ids);
        this.changeDetectionRef.markForCheck();
      });
    this.channelRepository.getSelectedChannel().pipe(takeUntil(this.isAlive$)).subscribe((channel: Channel) => {
       if(!!channel){
        this.selectedChannel = channel;
        this.iomChannel = this.selectedChannel?.is_iom;
        this.changeDetectorRef.markForCheck();
       }
      });
    this.listenToBroadcasterEvents();
    this.route.queryParams.pipe(takeUntil(this.isAlive$)).subscribe(queryParams => {
      this.markElements(queryParams["search"]);
    });
    this.listenParamChanges();
    this.fetchSelectedTopicFiles();
    this.route.params.pipe(takeUntil(this.isAlive$)).subscribe(params => {
      if (params) {
        this.selfTopicId = params.topicId;
        this.broadcaster.broadcast("showRightPanel");
        if (document.getElementById("mainLayout") !== null) {
          document.getElementById("mainLayout").classList.add("hide-header-mobile");
        }
        this.channelRepository.setSelectedTopic(null);
        this.topic = null;

        if (params.channelId) {
          this.channelId = params.channelId;
        }
        if (this.router.url.indexOf("iom") !== -1)
          this.iomChannel = true;
        if (params.topicId) {
          this.channelRepository.setSelectedTopic(params.topicId);
          this.topicId = params.topicId;
          this.markElements();

          this.channelRepository.readAllTopicComments(this.topicId, this.topic?.is_iom ? this.topic?.channel_id : null); // while marking comments to read, topic will also be marked as read
          this.channelRepository.getTopicById(this.topicId).pipe(take(1)).subscribe((topic: any) => {
            if (!!topic && topic?.loaded) {
              return;
            } else {
              this.channelRepository.
                getTopicDetail(this.topicId, this.iomChannel ? this.channelId : null)
                .pipe(take(1))
                .subscribe((topic: any) => {
                  let currentUrl = CommonUtil.getBaseOriginUrl();
                  this.topicURL = `${currentUrl}/talk/channels/${this.topic.channel_id}/topics/${this.topic.id}`;
                }, (err) => {
                  if (err && err?.error?.error?.includes("Forbidden")) {
                    this.noAccess = true;
                    this.changeDetectorRef.markForCheck();
                  } else {
                    this.router.navigateByUrl(`/talk/channels`);
                  }
                }
                );
            }
            this.changeDetectorRef.markForCheck();
          });
          this.channelRepository.getCommentsLoading(this.topicId).pipe(takeUntil(this.isAlive$)).subscribe((loading: boolean) => {
            this.commentsLoading = loading;
            this.changeDetectorRef.markForCheck();
          });
        }

        this.channelRepository.getSelectedTopic().pipe(takeUntil(this.isAlive$)).subscribe((topic) => {
          if (topic) {
            this.setupTopic(topic);
          }
        });
      }
    });

    this.route.queryParams.pipe(takeUntil(this.isAlive$))
      .subscribe(qp => {
        if (qp["highlight_files"]) {
          setTimeout(() => {
            this.channelRepository.openSideBar("topic", this.topicId, "Files");
          }, 2000);
        }
      });

    this.broadcaster.on("HIGHLIGHT_TOGGLE").pipe(takeUntil(this.isAlive$))
      .subscribe(val => {
        if (val === false) {
          this.unmark();
          this.changeDetectorRef.markForCheck();
        }
      });
    this.broadcaster.on<any>("backTopicDetail")
      .pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.backToChannel();
    });
    this.fetchTopicFilesInfo();
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 767px)");
    this.breakpointObserver
      .observe(["(max-width: 767px)"])
      .pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobileScreen = true;
        } else {
          this.isMobileScreen = false;
        }
        this.changeDetectorRef.markForCheck();
    });

    this.broadcaster.on("SCROLL_TO_COMMENT").pipe(takeUntil(this.isAlive$)).subscribe(() => {
        this.addComment();
    });

    this.broadcaster.on<any>(TOPIC_FILES_ADDED)
      .pipe(takeUntil(this.isAlive$)).subscribe(() => {
      this.fetchSelectedTopicFiles();
    });

    this.broadcaster.on<any>(BroadcastKeys.RENAME_FILE).pipe(takeUntil(this.isAlive$))
      .subscribe((file) => {
        this.renameFile(file);
    });
    if(environment.isCordova){
      StatusBar.backgroundColorByHexString("#000000");
      StatusBar.styleBlackTranslucent();
    }
  }

  isShowPlayIcon(topic: Topic) {
    let returValue: boolean = false;
    if ((topic?.heroAttachments && topic.heroAttachments?.length > 0) || (!!topic?.default_cover_url)) {
      if (topic?.heroAttachments?.length > 0 && !!topic?.heroAttachments[0]?.thumbnail_url) {
        returValue = true;
      }
      if (!(topic?.heroAttachments && topic.heroAttachments?.length > 0) || (topic.heroAttachments?.length > 0 && topic.heroAttachments[0]?.thumbnail_url === undefined)) {
        returValue = false;
      }
      if( (topic?.attachments && topic.attachments?.length > 0) && !topic.attachments[0]?.content_type?.toLowerCase().includes("image")){
        returValue = true;
      }
    }
    return returValue;
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.descriptionImagesLength = document.querySelector(".topic-body")?.querySelector(".description-info")?.querySelectorAll("img")?.length;
      this.updateDescriptionImageHeight();
    }, 500);
    this.bindInlineImageClickEvents();
    of(null).pipe(
      delay(1100)
    ).subscribe(() => {
      const queryParams = this.route.snapshot?.queryParams;
      if (queryParams["comment"] === "comment-form") {
        const topicDetailComponent = this.elementRef.nativeElement?.querySelector(".topic-detail");
        const commentForm = this.elementRef.nativeElement?.querySelector(".comment-form");
        commentForm?.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
    of(null).pipe(
      delay(2000)
    ).subscribe(() => {
     let element = this.elementRef.nativeElement?.querySelector(".comment-editor .text-area");
     if (element && element.click) {
      element.click();
    }
    });
  }

  private setupTopic(topic: any) {
    if(!!topic) topic.description = topic?.description?.replace(/<video[^>]*>(.*?)<\/video>/g, "");
    const attachments = topic?.attachments?.map(attachment => {
      return  {
        ...attachment,
        content_url: topic.is_iom ? attachment.content_url : CommonUtil.getAttachmentLocalAPIURL(attachment.content_url),
        thumbnail_url: CommonUtil.getThumbnailURL(topic.is_iom ? attachment.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(attachment.thumbnail_url), 800)
      };
    });
    let embeddedVideoHTML = "";
    if (topic.external_video_url) {
      embeddedVideoHTML = this.setEmbedVideo(topic?.external_video_url);
      embeddedVideoHTML = this.sanitizer.sanitize(SecurityContext.HTML,
        this.sanitizer.bypassSecurityTrustHtml(embeddedVideoHTML));
    }
    this.topic = {
      ...topic,
      attachments,
      embeddedVideoHTML,
      related_topics: this.relatedTopicsService.getMappedRelatedTopicData(topic?.related_topics)
    };
    if (this.topic?.deleted) {
      this.bannerMsg = "DELETED_TOPIC";
      this.bannerActionLabel = "RESTORE";
    }
    if (this.topic?.archived) {
      this.bannerMsg = "ARCHIVED_TOPIC_LABEL";
      this.bannerActionLabel = "UNARCHIVE";
    }
    this.topic.related_topics = this.topic.related_topics?.filter((relatedTopic: any) => {
      return relatedTopic?.deleted === false;
    });
    this.updateReferenceIndices();
    this._smartObjectsService.updateSmartObjectIcons(this.getTopicDetailWrapperId());
    this.stickyNotes = topic.sticky_notes || [];
    this.channelRepository?.setSelectedChannelId(topic?.channel_id);
    let headerAttachments = [];
    if (topic.topic_type === "video") {
      if (topic.external_video_url) {
        const thumbnail = topic.attachments.find(attachment => attachment.is_default_thumbnail === true);
        if (!!thumbnail) {
          headerAttachments = [{ ...thumbnail }];
        }
      } else {
        const video = topic.attachments.find(attachment => attachment.is_video === true);
        let defaultThumbnail = topic.attachments.find(attachment => attachment.is_default_thumbnail === true);
        if (!!video) {
          let thumbnail_url = video.thumbnail_url;
          if (video && defaultThumbnail) {
            thumbnail_url = defaultThumbnail.thumbnail_url;
          }
          headerAttachments = [{ ...video, thumbnail_url }];
        }
      }
    } else {
      headerAttachments = topic?.attachments?.filter(attachment => attachment?.is_header === true);
    }
    this.heroAttachments = this.channelRepository?.updateAttachmentsURLs(headerAttachments, topic?.is_iom);
    if (this.topic) {
      this.topic.description = CommonUtil.getViewInlineImages(this.topic?.description, 800);
    }
      this.topic.description = CommonUtil.linkifyHTML(this.topic.description);
      if (this.topic?.topic_type === "video") {
        if (this.topic?.external_video_url) {
          this.embeddedVideoLink = this.topic?.external_video_url;
          this.sanitizedEmbeddedVideoLink =  this.sanitizer.bypassSecurityTrustResourceUrl(this.embeddedVideoLink);
          if (!!this.topic?.embeddedVideoHTML) {
            this.embeddedVideoHTML = this.topic?.embeddedVideoHTML;
          }
        } else {
          this.video = this.topic?.attachments.find((a: any) => a.is_video);
          this.defaultThumbnail = this.topic?.attachments.find((a: any) => a.is_default_thumbnail);
        }
      } else {
        this.video = this.defaultThumbnail = null;
        this.embeddedVideoHTML = this.embeddedVideoLink = "";
      }
    this.changeDetectorRef.markForCheck();
  }

  private listenToBroadcasterEvents() {
    this.broadcaster.on(ConstantsUtil.SEND_TOPICS).pipe(takeUntil(this.isAlive$)).subscribe((topics) => this.onSendRelatedTopics(topics));
    this.broadcaster.on("update_bottom_remarks")
    .pipe(takeUntil(this.isAlive$))
    .subscribe((val:any) => {
      this.remarks = val;
      this.changeDetectorRef.markForCheck();
    });
    this.broadcaster.on(BroadcastKeys.TOPIC_EDIT_CREATE).pipe(takeUntil(this.isAlive$))
    .subscribe((status:boolean) => {
      this.shouldShowDesc = status;
      this.updateReferenceIndices();
    });
  }

  async permanentlyDeleteTopic(topic: Topic) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "DELETE_TOPIC",
        bodyText: "DELETE_TOPIC_MSG",
        okLabel: "DELETE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepository.deleteTopic(topic.id, topic.channel_id, topic.read);
        }
      }
    });
  }

  setEmbedVideo(videoUrl: string) {
    let embeddedVideoHTML;
    let newVideoUrl = new URL(videoUrl);
    if ((newVideoUrl?.hostname === "youtu.be" || newVideoUrl?.hostname === "youtube.com")) {
      const urlFragments =  videoUrl.split("?");
      const urlParams = new URLSearchParams("?" + urlFragments[1]);
      if (!!urlParams.get("t")) {
        let startTime: string = urlParams.get("t");
        if (startTime?.length > 0 && startTime.charAt(startTime.length - 1) === "s") {
          startTime = startTime.substring(0, startTime.length - 1);
        }
        let updatedVideoUrl = new URL(videoUrl.split("?")[0]);
        urlParams.forEach(p => {
          if (p !== "t" && urlParams.get(p)) {
            updatedVideoUrl.searchParams.set(p, urlParams.get(p));
          }
        });
        videoUrl = updatedVideoUrl.toString();
        embeddedVideoHTML = this.embedService.embed(videoUrl, {attr: { width: "100%", height: 360 }, query: {start: startTime}});
        return embeddedVideoHTML;
      } else {
        embeddedVideoHTML = this.embedService.embed(videoUrl, {attr: { width: "100%", height: 360 }});
        return embeddedVideoHTML;
      }
    } else if (newVideoUrl?.hostname === "vimeo.com" && videoUrl.includes("#")) {
      const id = videoUrl.split("vimeo.com/")[1];
      embeddedVideoHTML = this.embedService.embed_vimeo(id, {attr: { width: "100%", height: 360 }});
      return embeddedVideoHTML;
    } else {
      embeddedVideoHTML = this.embedService.embed(videoUrl, {attr: { width: "100%", height: 360 }});
      return embeddedVideoHTML;
    }
}
  updateDescriptionImageHeight() {
    if (!this.allImagesUpdated && this.retryCount < this.maxRetryCount) {
      setTimeout(() => {
        const descriptionImages = document.querySelector(".topic-body")?.querySelector(".description-info")?.querySelectorAll("img");
        if (descriptionImages) {
          for (let i = 0; i < descriptionImages?.length; i++) {
            if (descriptionImages[i].width === 0) {
              this.retryCount += 1;
              this.updateDescriptionImageHeight();
              return;
            }
            if (!this.imgUpdateLog.hasOwnProperty(i) && descriptionImages[i].width === 800) {
              descriptionImages[i].style.width = "100%";
              this.imgUpdateLog[i] = true;
            }
            if (!this.imgUpdateLog.hasOwnProperty(i) && descriptionImages[i].width > 0 && descriptionImages[i].width < 800) {
              this.imgUpdateLog[i] = "NA";
            }
          }
        }
      }, 300);
    }
  }
  get allImagesUpdated() {
    return this.descriptionImagesLength === Object.keys(this.imgUpdateLog).length;
  }

  fetchSelectedTopicFiles() {
    this.channelRepository.getSelectedTopicId()
      .pipe(takeUntil(this.isAlive$),filter(selectedTopicId => !!selectedTopicId), switchMap((selectedTopicId: Topic["id"]) => {
        return this.channelRepository.getTopicFiles(selectedTopicId, false, this.topic?.is_iom ? this.topic?.channel_id : null).pipe(takeUntil(this.isAlive$));
      })).subscribe((topicFiles: File[]) => {
        this.topicFilesLength = topicFiles.length;
        const attachmentFiles = [...topicFiles].filter(file => !file.is_header && !file.is_inline);
        this.$topicFiles.next(attachmentFiles.map(k => ({...k, thumbnail_url: this.topic?.is_iom ? k.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(k.thumbnail_url), content_url: this.topic?.is_iom ? k.content_url : CommonUtil.getAttachmentLocalAPIURL(k.content_url), filename: !!k?.description ? k?.description : k?.filename})));
      });
  }


  fetchMoreTopicFiles() {
    this.$fetchingMoreFiles.next(true);
    this._translate.get("LOADING_MORE_ATTACHMENTS")
      .pipe(take(1))
      .subscribe(text => {
        this.snackBar.open(text, null, {duration: 2000});
      });
    this.channelRepository.getSelectedTopicId()
      .pipe(takeUntil(this.isAlive$),filter(selectedTopicId => !!selectedTopicId), switchMap((selectedTopicId: Topic["id"]) => {
        return this.channelRepository.loadMoreTopicFiles(selectedTopicId, this.topic?.is_iom ? this.topic?.channel_id : null).pipe(takeUntil(this.isAlive$));
      })).subscribe((topicFiles: File[]) => {
      this.topicFilesLength = topicFiles.length;
      const attachmentFiles = [...topicFiles].filter(file => !file.is_header && !file.is_inline);
      this.$topicFiles.next(attachmentFiles.map(k => ({...k, thumbnail_url: this.topic?.is_iom ? k.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(k.thumbnail_url), content_url: this.topic?.is_iom ? k.content_url : CommonUtil.getAttachmentLocalAPIURL(k.content_url), filename: !!k?.description ? k?.description : k?.filename})));
      this.$fetchingMoreFiles.next(false);
    });
  }

  private fetchTopicFilesInfo() {
    this.channelRepository.getSelectedTopicId()
      .pipe(takeUntil(this.isAlive$),filter(selectedTopicId => !!selectedTopicId), switchMap((selectedTopicId: Topic["id"]) => {
        return this.channelRepository.getTopicFilesInfo(selectedTopicId).pipe(takeUntil(this.isAlive$));
      })).subscribe(res => {
      this.topicFilesInfo = res;
    });
  }

  listenParamChanges() {
    this.route.queryParams.pipe(filter(value => value.hasOwnProperty("comment")), takeUntil(this.isAlive$)).subscribe((queryParams: {comment: TopicCommentScroll}) => {
      const comment = queryParams["comment"];
      this.focusToCommentArea = (comment === TopicCommentScroll.COMMENT_FORM);
      if (comment === TopicCommentScroll.COMMENT_ITEMS) {
        this.scrollToCommentItems(200);
      }
    });
  }

  removeAllAttachments(attachments: any[]) {
    if (this.getPermission(this.topic?.permissions, "editable")) {
      let ids = attachments?.map(attachment => {
        return attachment?.id;
      });
      this.channelService.deleteTopicAttachments(ids).pipe(take(1)).subscribe(resp => {
        this.fetchSelectedTopicFiles();
      }, (err) => {
        this.translate.get("SOME_UNKNOWN_ERROR")
          .pipe(take(1))
          .subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
          });
      });
    } else {
      this.translate.get("NOT_ALLOWED").pipe(take(1)).subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
      });
    }
  }

  scrollToCommentItems(delayTime = 0) { // time is in milliseconds (ms)
    this.broadcaster.on(TOPIC_COMMENTS_LOADED).pipe(take(1), delay(delayTime), takeUntil(this.isAlive$)).subscribe(() => {
      let commentsList = document.querySelector(".comments-list");
      if (commentsList?.previousElementSibling) {
        commentsList.previousElementSibling.scrollIntoView({
          behavior: "smooth"
        });
      }
    });
  }

  openCommentForm() {
    this.focusToCommentArea = true;
    let vncComments = document.querySelector("vnc-comments");
    if (vncComments) {
      vncComments.scrollIntoView({
        behavior: "smooth"
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  openRelatedTopicSection() {
    this.focusToRelatedTopicsSection = true;
    const vpRelatedTopicsList = document.querySelector("vp-related-topics-list");
    if (vpRelatedTopicsList) {
      vpRelatedTopicsList.scrollIntoView({
        behavior: "smooth"
      });
    }
    this.changeDetectorRef.markForCheck();
  }

  onCommentFormClear() {
    this.focusToCommentArea = false;
    this.changeDetectorRef.markForCheck();
  }
  enableDisableComments(){
    this.channelRepository.updateTopic(this.topic?.id, {
      disable_comments : this.topic?.comments_disabled ? 0 : 1,
    }).pipe(take(1)).subscribe((resp: any) =>{
    },(err) =>{
    });
  }

  generateHeroHeader(attachments: any, isVideoType = false, isExternalVideo = false, isIOM = false) {
    if (isVideoType) {
      if (isExternalVideo) {
        let headerAttachments = attachments.filter(attachment => attachment.is_default_thumbnail === true);
        return headerAttachments.map(attachment => {
          const content_url = isIOM ? attachment.content_url : CommonUtil.getAttachmentLocalAPIURL(attachment.content_url);
          const thumbnail_url = isIOM ? attachment.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(attachment.thumbnail_url);
          return {...attachment, content_url, thumbnail_url};
        });
      } else {
        let heroAttachment = attachments.find(attachment => attachment.is_video === true);
        let defaultThumbnail = attachments.find(attachment => attachment.is_default_thumbnail === true);
        if (defaultThumbnail && heroAttachment) {
          heroAttachment.thumbnail_url = defaultThumbnail.thumbnail_url;
        }
        if (!!heroAttachment) {
          return  [{
            ...heroAttachment,
            content_url: isIOM ? heroAttachment.content_url : CommonUtil.getAttachmentLocalAPIURL(heroAttachment.content_url),
            thumbnail_url: isIOM ? heroAttachment.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(heroAttachment.thumbnail_url)
          }];
        } else {
          return [];
        }
      }
    } else {
      let headerAttachments = attachments.filter(attachment => attachment.is_header === true);
      return headerAttachments.map(attachment => {
        const content_url = isIOM ? attachment.content_url : CommonUtil.getAttachmentLocalAPIURL(attachment.content_url);
        let thumbnail_url = isIOM ? attachment.thumbnail_url : CommonUtil.getAttachmentLocalAPIURL(attachment.thumbnail_url);
        if (CommonUtil.bytesToKiloBytes(attachment?.filesize) < this.sizeLimitForNotUseThumbnailURL ) {
          thumbnail_url = isIOM ? attachment.content_url : CommonUtil.getAttachmentLocalAPIURL(attachment.content_url);
        }
        return {...attachment, content_url, thumbnail_url};
      });
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.channelRepository.setSelectedTopic(null);
    this.broadcaster.broadcast(ConstantsUtil.TOGGLE_SIDEBAR_FULL_EXPANSION, false);
    this.isAlive$.complete();
    if (environment.isCordova) {
      StatusBar.backgroundColorByHexString("#317bbc");
    }
  }

  underDevelopment() {
    this.translate.get("UNDER_DEVELOPMENT")
      .pipe(take(1))
      .subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
      });
  }

  backToChannel() {
    this._topicFilterService.resetFilters();
    this.router.navigate(["../../"], { relativeTo: this.route, state: { prevTopicId: this.topicId } });
  }

  async editTopic() {
    this.imgUpdateLog = {};
    this.retryCount = 0;
    const options = {
      maxWidth: "100%",
      maxHeight: "100%",
      width: "100vw",
      height: "100vh"
    };
    const { CreateTopicComponent } = await import(
      /* webpackPrefetch: true */
      "app/channels/create-topic/create-topic.component");
    this.matDialog.open(CreateTopicComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: [ "vnctalk-form-panel", "vnctalk-create-topic-panel"],
      disableClose: true,
      data: this.topic,
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe((res:any) => setTimeout(() => {
      if (res) {
        if(res?.is_iom) {
          this.setupTopic(res);
          this.updateDescriptionImageHeight();
          this.fetchSelectedTopicFiles();
          this.updateReferenceIndices();
          this.changeDetectionRef.markForCheck();
        }
      }
      setTimeout(() => {
        this._smartObjectsService.updateSmartObjectIcons(this.getTopicDetailWrapperId());
      }, 2000);
    }, 250));
  }

  heroImageClicked(event: any) {
    let attachments = [];
    if (this.topic.topic_type === "video") {
      if (this.topic.external_video_url) {
        return;
      } else {
        let heroAttachment = this.topic.attachments.find(attachment => attachment.is_video === true);
        let defaultThumbnail = this.topic.attachments.find(attachment => attachment.is_default_thumbnail === true);
        if (defaultThumbnail && heroAttachment) {
          heroAttachment.thumbnail_url = defaultThumbnail.thumbnail_url;
        }
        attachments = [{...heroAttachment}];
      }
    } else {
      attachments = this.topic.attachments.filter(a => a.is_header);
    }
    if (attachments.length > 0) {
      this.channelRepository.showFilePreview(attachments, event, false, this.topic?.is_iom);
    }
  }

  fileItemClicked(files: any[], event: any) {
    this.channelRepository.showFilePreview(files, event, false, this.topic?.is_iom);
  }

  async deleteTopic(item, moveToTrash = false) {
    if (moveToTrash) {
      this.channelRepository.deleteTopic(item.id, item.channel_id, item.read, true, true);
      return;
    }
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "DELETE_TOPIC",
        bodyText: "DELETE_TOPIC_MSG",
        okLabel: "DELETE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
          if (res.confirmation === "yes") {
            this.channelRepository.deleteTopic(item.id, item.channel_id, item.read, true);
          }
      }
    });
  }

  restoreTopic(item) {
    this.channelRepository.restoreTopic(item?.id).subscribe(() => {
      this.translate.get("TOPIC_RESTORED").pipe(take(1)).subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
      });
      this.topicRestored = true;
    });
  }

  async archiveTopic(item) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "ARCHIVE_TOPIC",
        bodyText: "ARCHIVE_TOPIC_MSG",
        okLabel: "ARCHIVE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
          if (res.confirmation === "yes") {
            this.channelRepository.archiveTopic(item.id, item.channel_id, item.read);
          }
      }
    });
  }

  async unarchiveTopic(item) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "UNARCHIVE_TOPIC",
        bodyText: "UNARCHIVE_TOPIC_MSG",
        okLabel: "UNARCHIVE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepository.unarchiveTopic(item.id).pipe(take(1)).subscribe(() => {
            this.toggleUnarchivedBanner(true);
          });
        }
      }
    });
  }

  toggleUnarchivedBanner(value = false) {
    this.showUnarchivedBanner = value;
    this.changeDetectorRef.markForCheck();
  }

  likesTopic(item) {
    if (item?.archived) { return; }
    if (item && item.liked) {
      this.topic.liked = false;
      this.topic.likes_count = (this.topic.likes_count || 0) - 1;
      this.channelRepository.unlikesTopic(item.id, item.is_iom ? item.channel_id : null)
        .pipe(take(1))
        .subscribe((topic: Topic) => {
          if (topic)
          {
            this.topic = { ...this.topic, likes_count: topic.likes_count, liked: topic.liked };
            this.changeDetectorRef.markForCheck();
          }
        });
    } else {
      this.topic.liked = true;
      this.topic.likes_count = (this.topic.likes_count || 0) + 1;
      this.channelRepository.likesTopic(item.id, item.is_iom ? item.channel_id : null)
        .pipe(take(1))
        .subscribe((topic: Topic) => {
          if (topic)
          {
            this.topic = { ...this.topic, likes_count: topic.likes_count, liked: topic.liked };
            this.changeDetectorRef.markForCheck();
          }
        });
    }
  }

  async openSendTopicLinkPopup(item: Topic) {
    let width = "480px";
    let height = "572px";
    let maxWidth = "80vw";
    const sendTopicLinkRef = this.matDialog.open(SendTopicLinkComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      width: width,
      height: height,
      maxWidth: maxWidth,
      autoFocus: true,
      data: {topic: item}
    });
    sendTopicLinkRef.afterClosed()
      .pipe(takeUntil(this.isAlive$))
      .subscribe( (val: any)=> {
        if (val?.selectedUserIds?.length > 0) {
          let comment = `Hey, checkout this topic`;
          this._translate.get("HEY_CHECKOUT_THIS_TOPIC").pipe(take(1)).subscribe((text: string) => {
            comment = text + " :";
          });
          if (!!val?.comment) {
            comment = val.comment + " :";
          }
          const currentUrl = CommonUtil.getBaseOriginUrl();
          val?.selectedUserIds?.forEach((jId) => {
            const type = jId.includes("@conference") ? "groupchat" : "chat";
            this.conversationRepo.sendMessage({
              body: `${comment} ${currentUrl}/talk/channels/${item?.channel_id}/topics/${item?.id}`,
              type: type
            }, jId, type);
          });
          this._translate.get("LINK_SENT").pipe(take(1)).subscribe((text: string) => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
        }
      });
  }

  async openMoveToChannelDialog(item: Topic) {
    let width = "480px";
    let height = "572px";
    let maxWidth = "80vw";
    const { MoveToChannelComponent } = await import(
      "../move-to-channel/move-to-channel.component");
    const moveToChannelRef = this.matDialog.open(MoveToChannelComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass   : "vnctalk-form-panel",
      width        : width,
      height       : height,
      maxWidth     : maxWidth,
      autoFocus    : true,
      data         : {topic: item}
    });
    moveToChannelRef.afterClosed()
      .pipe(takeUntil(this.isAlive$))
      .subscribe((val: any) => {
        this.channelRepository.updateTopic(this.topicId, {channel_id: val.channel.id}).pipe(take(1)).subscribe(
          () => {
            this.translate.get("TOPIC_MOVED_TO_SELECTED_CHANNEL", {channelName: "\"" + val?.channel?.name + "\""}).pipe(take(1)).subscribe(
              res => {
                this.channelRepository.moveTopic(this.topic, val.channel, this.topic?.channel_id);
                this.channelSnackBarService.openSnackBar(res, SnackbarType.CHECKMARK);
                this.channelRepository.setSelectedChannelId(val.channel.id);
                this.router.navigateByUrl(this.router.url.replace(item.channel_id, val.channel.id));
              });
          });
      });
  }
  async openCloneToChannelDialog(item: Topic) {
    let width = "480px";
    let height = "572px";
    let maxWidth = "80vw";
    const { MoveToChannelComponent } = await import(
      "../move-to-channel/move-to-channel.component");
    const moveToChannelRef = this.matDialog.open(MoveToChannelComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass   : "vnctalk-form-panel",
      width        : width,
      height       : height,
      maxWidth     : maxWidth,
      autoFocus    : true,
      data         : {topic: item, clone: true}
    });
    moveToChannelRef.afterClosed()
      .pipe(takeUntil(this.isAlive$))
      .subscribe((val: any) => {
        this.channelRepository.cloneTopicToChannel(item.id, val.channel.id);
        this.channelRepository.setSelectedChannelId(val.channel.id);
        this.router.navigateByUrl(this.router.url.replace(item.channel_id, val.channel.id));
      });
  }

  showTopicInfo(item: Topic) {
    this.channelRepository.openSideBar("topic", item.id);
  }

  openTopicFiles(item: Topic) {
    // this.channelRepository.openSideBar("topic", item.id, "Files");
    this.channelRepository.openSideBar("topic", item.id, "Files");
  }

  copyTopicLink(item: any) {
    if(!item) {
      item = this.topic;
    }
    this.channelRepository.copyLink("topic", item.channel_id, item.id);
  }

  subscribeTopic(item) {
    this.channelRepository.subscribeTopic(item.id);
  }

  socialSharing(url: string) {
    if (!!this.electronService?.isElectron) {
      this.electronService.openExternalUrl(url);
    } else if (environment?.isCordova) {
      window.open(url, "_system", "location=yes");
    } else {
      window.open(url, "_blank");
    }
   }

   linkdlnShare(url: string) {
    window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + url, "_system","location=yes");
  }

  shareViaEmail(url: string) {
    if (!!this.electronService?.isElectron) {
      this.electronService.openExternalUrl(url);
    } else if (environment?.isCordova) {
      window.open(url, "_system", "location=yes");
    } else {
      window.open(url, "_blank");
    }
  }

  async unsubscribeTopic(item) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }

    const bodyText = this.channelRepository.getTranslatedText("UNSUBSCRIBE_FROM_TOPIC_MSG", {key: "topicName", value: item?.subject});
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "UNSUBSCRIBE_FROM_TOPIC",
        bodyText,
        okLabel: "UNSUBSCRIBE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          this.channelRepository.unsubscribeTopic(item.id).pipe(take(1)).subscribe();
        }
      }
    });
  }

  readTopic(item) {
    this.channelRepository.readTopic(item.id);
  }

  goBack(destination: "archive" | "trash" | "trash-channels" | "trash-topics" | "archive-channels" | "archive-topics" = "archive") {
    if (destination === "trash" || destination === "trash-channels") {
      this.router.navigate(["/talk/trash"], { queryParams: { selectedTabIndex: 0 } });
      return;
    }
    else if (destination === "archive" || destination === "archive-channels") {
      this.router.navigate(["/talk/archive"], { queryParams: { selectedTabIndex: 0 } });
      return;
    }
    else if (destination === "trash-topics") {
      this.router.navigate(["/talk/trash"], { queryParams: { selectedTabIndex: 1 } });
      return;
    }
    else if (destination === "archive-topics") {
      this.router.navigate(["/talk/archive"], { queryParams: { selectedTabIndex: 1 } });
      return;
    }
    this.router.navigate(["/talk/archive"], { queryParams: { selectedTabIndex: 1 } });
  }

  unreadTopic(item) {
    this.channelRepository.unreadTopic(item.id);
  }

  getPlainDescription(text) {
    return CommonUtil.getPlainText(text);
  }


  addFileToTopic(filesArray: any, topic:Topic) {
    if (!!filesArray?.length) {
      let files: any = [];
      for (let i = 0; i < filesArray.length; i++) {
        const file = filesArray[i];
        if (file) {
          let isFileExceedLimit = this.channelRepository.isFileSizeExceedLimit(file);
          if (isFileExceedLimit) {
            this.channelRepository.showFileExceedNotification(file);
          } else {
            files.push(file);
          }
        }
      }
      if (files.length > 5) {
        this.showNotification("FILE_UPLOAD_LIMIT");
        return;
      }
      if (this.isUploadRunning) {
        this.showNotification("FILE_UPLOAD_ALREADY_RUNNING_MSG");
        return;
      }
      this.totalFilesUploading = files.length;
      this.readFiles(files).pipe(take(1)).subscribe(fileUploads => {
        this.isUploadRunning = true;
        this.changeDetectorRef.markForCheck();
        this.uploadFiles(fileUploads).pipe(take(1)).subscribe(response => {
          this.uploadedFiles = [...response];
          const uploads = {};
          this.uploadedFiles.forEach(function(value, idx) {
            uploads[(idx + 1)] = value;
          });
          this.channelRepository.updateTopic(topic.id, {...topic, uploads}).pipe(take(1)).subscribe((newTopic:Topic) =>{
            this.channelRepository.addFilesToTopicStore(newTopic.id, newTopic.attachments,this.uploadedFiles.length);
            this.broadcaster.broadcast(TOPIC_FILES_ADDED, {files: uploads});
          });
          // this.channelRepository.addFilesToTopicStore(topic.id, {...topic, uploads}).subscribe();
          this.isUploadRunning = false;
          this.totalFilesUploading = 0;
          this.showSuccessBanner = true;
          this.changeDetectorRef.markForCheck();
          setTimeout(() => {
            this.dismissSuccessBanner();
          },5000);
        });
      });
    }
  }

  filterRelatedTopics(topics) {
  if(!!topics) {
    topics.filter((topic: Topic) =>{
      if(!topic?.deleted) {
        return;
      }
    });
  }
  }

  showNotification(key:string, params?:any){
    this.translate.get(key, params).pipe(take(1)).subscribe(text => {
      this.snackBar.open(text, "X", {
        duration: 5000
      });
    });
  }

  private readFiles(files: any[]): Observable<any> {
    const fileReaderObs = (file) => {
      let myReader: FileReader = new FileReader();
      let fileReaderObs = Observable.create((observer: any) => {
        myReader.onloadend = () => {
          let upload = {
            filename: file?.name,
            content_type: file.type,
            content: myReader.result
          };
          if(!upload.content_type)
          {
            this.translate.get("UNSUPPORTED_FILE").pipe(take(1)).subscribe(resp=>{
              this.snackBar.open(resp);
              setTimeout(() => {
                this.snackBar.dismiss();
              }, 3000);
              return "invalid file type";
            });
          }
          else{
            observer.next(upload);
            observer.complete();
          }
        };
      });
      myReader.readAsArrayBuffer(file);
      return fileReaderObs;
    };

    const observableUploadFilesBatch = [];
    for (let i = 0; i < files.length; i++) {
      observableUploadFilesBatch.push(fileReaderObs(files[i]));
    }
    return forkJoin(observableUploadFilesBatch);
  }

  private uploadFiles(fileUploadsArray: any[]): Observable<any> {
    const fileUploads: any = [];
    for (let i = 0; i < fileUploadsArray.length; i++) {
      fileUploads.push(
        this.channelService.uploadFile(fileUploadsArray[i].content, fileUploadsArray[i].filename, fileUploadsArray[i].content_type).pipe(
          map((res: any)  => res.upload),
          catchError(() => of(
            this.showNotification("FILE_UPLOAD_ERROR")
          ))
        )
      );
    }
    return forkJoin(fileUploads);
  }

  dismissSuccessBanner(click?:MouseEvent) {
    click?.preventDefault();
    click?.stopPropagation();
    this.totalFilesUploading = 0;
    this.showSuccessBanner = false;
    this.changeDetectorRef.markForCheck();
  }


  showNewUploadedFiles() {
    this.router.navigateByUrl(`/talk/channels/${this.channelId}/topics/${this.topicId}?comment=false&highlight_files=${JSON.stringify(this.uploadedFiles.map(k => k.id))}`);
  }


  private unmark() {
    this.topicBodyInstance?.unmark();
    this.topicTitleInstance?.unmark();
    this.channelInfoInstance?.unmark();
    this.currentHighlightedIndex = 1;
    this.totalHighLights = 0;
  }


  private markElements(searchTerm = null) {
    if(!this.isMarkedEarlier){
      this.isMarkedEarlier = true;
      return;
    }
    searchTerm = searchTerm || this.route.snapshot.queryParams["search"];
    this.unmark();
    if(!searchTerm){
      return;
    }
    let markedElementCount = 1;
    try {
      setTimeout(() => {
        this.topicBodyInstance = new Mark(document.querySelector(".topic-body"));
        this.topicTitleInstance = new Mark(document.querySelector(".author-info"));
        this.channelInfoInstance = new Mark(document.querySelector(".channel-info"));

        this.topicTitleInstance.mark(searchTerm, {
          className: "topic-search-mark", each: (elem) => {
            this.scrollData[markedElementCount] = elem?.getBoundingClientRect().top;
            elem.classList.add(`mark-vnc-${markedElementCount++}`);
            ++this.totalHighLights;
          }
        });
        this.topicBodyInstance.mark(searchTerm, {
          className: "topic-search-mark", each: (elem) => {
            this.scrollData[markedElementCount] = elem?.getBoundingClientRect().top;
            elem.classList.add(`mark-vnc-${markedElementCount++}`);
            ++this.totalHighLights;
          }
        });

        this.channelInfoInstance.mark(searchTerm, {
          className: "topic-search-mark", each: (elem) => {
            this.scrollData[markedElementCount] = elem?.getBoundingClientRect().top;
            elem.classList.add(`mark-vnc-${markedElementCount++}`);
            ++this.totalHighLights;
          }
        });

        this.highlightCurrent();
        this.changeDetectorRef.detectChanges();
      }, 1000);
    } catch (e) {
    }
  }


  onSearchMatchNavigation(type: any) {
    if (type === "next") {
      if (this.currentHighlightedIndex === this.totalHighLights) {
        this.currentHighlightedIndex = 1;
      }
      else {
        ++this.currentHighlightedIndex;
      }
    }
    else if (type === "previous") {
      if (this.currentHighlightedIndex === 1) {
        this.currentHighlightedIndex = this.totalHighLights;
      }
      else {
        --this.currentHighlightedIndex;
      }
    }
    else if (type === "first") {
      this.currentHighlightedIndex = 1;
    }
    else if (type === "last") {
      this.currentHighlightedIndex = this.totalHighLights;
    }
    this.highlightCurrent();
  }


  private highlightCurrent() {
    document.getElementsByClassName("topic-current-highlight")
      ?.item(0)
      ?.classList
      ?.remove("topic-current-highlight");
    document.getElementsByClassName(`mark-vnc-${this.currentHighlightedIndex}`)
      ?.item(0)
      ?.classList
      ?.add("topic-current-highlight");
    const container = document.getElementsByClassName("topic-detail-with-comments")?.item(0);
      container.scrollTo({top: this.scrollData[this.currentHighlightedIndex] - 150, behavior: "smooth"});
  }

  moveToComment() {
    let commentsList = document.querySelector(".comments-list");
    if (commentsList.previousElementSibling) {
      commentsList.previousElementSibling.scrollIntoView({
        behavior: "smooth"
      });
    }
  }

  copyFilesToClipboard(files) {
    let currentUrl = CommonUtil.getBaseOriginUrl();
    const fileUrls = files.map(file => `${currentUrl}/api/attachments/download/${file.id}/${file.filename}?disposition=inline`);
    const urlsString = fileUrls.join("\n");
    CommonUtil.copyToClipboard([urlsString]);
    this.translate.get("LINK_COPIED_TO_THE_CLIPBOARD").pipe(take(1)).subscribe(text => {
      this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
    });
  }

  downloadFiles(files: File[], isBulk = false) {
    if (!isBulk) {
      this.fileActionsService.downloadFile(files[0]);
    } else {
      this.fileActionsService.downloadFilesAsZIP(files, `topic-${this.topicId}.zip`);
    }
  }

  copyFileLink(file: File) {
    this.fileActionsService.copyFileLink(file);
  }

  requestAccess() {
    this.channelRepository.accessRequest(this.channelId, this.topicId).pipe(take(1)).subscribe(
      () => {
        this.router.navigateByUrl("/talk");
        if (CommonUtil.isOnMobileDevice()) {
          setTimeout(() => {
            this.broadcaster.broadcast("showLeftPanel");
            if (document.getElementById("mainLayout") !== null) {
              document.getElementById("mainLayout").classList.remove("hide-header-mobile");
              document.getElementById("mainLayout").classList.remove("hide-header-search-form");
            }
          }, 50);
        }
      }
    );
  }

  getItem(topic): any {
    const dateItem = this.getDateTimeInFormat(topic);
    return { filename: "" , author: {name: topic?.author?.name}, created_on: dateItem, thumbnail_url: "" , avatar_url: topic?.author?.avatar_url };
  }

  getDateTimeInFormat(topic: Topic): string {
    try {
      if (!!topic) {
        const formatStr = this.datetimeService.getFormatString(this.lang);
        const time = format(new Date(topic?.created_on), formatStr, { locale: this.lang === "de" ? de : enUS });
        return time;
      }
      return null;

    }
    catch {
      this.logger.info("date value passed undefined exception");
    }
  }

  iconPress(ev: any): void {
    if (ev === "arrow_back") {
      if (this.$showGoToParentBanner.getValue()) {
        this.goToParentActionClick(ev);
        return;
      }
      this.backToChannel();
    } else if (ev === "product-pannel") {
      this.showTopicInfo(this.topic);
    } else if (ev === "share-new") {
      if (this.topic.archived) {
        this.unarchiveTopic(this.topic);
      }
      else if(this.topic?.deleted) {
        this.restoreTopicOnConfirmation(this.topic);
      }
      else {
        this.archiveTopic(this.topic);
      }
    }
  }

  getCoverImgURL(topic): string {
    let returValue: string = "";
    if ( (this.heroAttachments && this.heroAttachments.length > 0) || (!!topic?.default_cover_url)) {
      if (this.heroAttachments.length > 0 && !!this.heroAttachments[0].thumbnail_url) {
        returValue = CommonUtil.getThumbnailURL(this.heroAttachments[0].thumbnail_url, 800);
      }
      if (!(this.heroAttachments && this.heroAttachments.length > 0)) {
        returValue = topic?.default_cover_url;
      }
    }
    return returValue;
  }

  loadMoreComments() {
    this.channelRepository.loadMoreComments(this.topicId, this.topic?.is_iom ? this.topic?.channel_id : null);
  }

  openContactPreview(jid: string, event) {
    if (this.jid === jid) {
      return;
    }
    const {clientX, clientY} = event;
    this._mentionService.getContactByBare(jid)
      .pipe(takeUntil(this.isAlive$))
      .subscribe(res => {
      const mappedContact = this._mentionService.getMappedContactForPreview(res?.[0] || {});
      if (Object.keys(mappedContact)?.length > 0 && !!mappedContact?.first_name) {
        this._mentionService.getContactPreviewDialogRef(mappedContact, clientX, clientY);
      }
    });
  }


  async onTopicDetailHover(event: any) {
    const targetElement: Element = event.target;
    const getAttribute = (element, attributeName) => element.getAttribute(attributeName);

    if (targetElement?.tagName === "SPAN" && getAttribute(targetElement, "data-denotation-char") === "@") {
      this.openContactPreview(getAttribute(targetElement, "data-id"), event);
      return;
    }

    if (!(targetElement?.tagName === "S" && getAttribute(targetElement, "data-type") === SmartObject.SMART_LINK.toLowerCase())) {
      return;
    }
    if (this.$previewAlreadyOpen.value) return;
    this.$previewAlreadyOpen.next(true);
    const smartLinkData = await this._smartLinkService.getSmartLinkData(targetElement).pipe(take(1)).toPromise();
    const filteredSmartLinkData = smartLinkData.filter(item => item.resultData.hasOwnProperty("id"));
    if (!filteredSmartLinkData.length) {
      this.translate.get("SMARTLINK_DELETED_OR_PRIVATE").pipe(take(1)).subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
      });
      this.$previewAlreadyOpen.next(false);
      return;
    }
    const data = {
      smartLinkData: filteredSmartLinkData,
      smartLinkName: targetElement.textContent,
      isEditable: !!this.topic?.permissions?.find(item => item?.hasOwnProperty("editable"))?.editable,
      shouldNavigate: true,
      isAnalyticsTopic: this.topic?.topic_type === "analytics"
    };

    setTimeout(async () => {
      const smartLinkPreviewRef = this._smartLinkService.getSmartLinkPreviewRef(data, event?.clientX, event?.clientY);
      smartLinkPreviewRef.componentInstance.$updateSmartlinkData.pipe(take(1)).subscribe(res => {
        const newSmartLinkElement: any = targetElement.cloneNode(true);
        newSmartLinkElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(res.data)));
        this.topic.description = this.topic?.description?.replace(targetElement.outerHTML, newSmartLinkElement.outerHTML);

        this.translate.get("UPDATING_THE_TOPIC").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
        this.channelRepository.updateTopic(this.topicId, this.topic).pipe(take(1)).subscribe(() => {
          this.translate.get("TOPIC_UPDATED").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
          this.changeDetectorRef.markForCheck();
        });
      });
      const value = await smartLinkPreviewRef.afterClosed().pipe(take(1)).toPromise();
      this.$previewAlreadyOpen.next(false);

      if (value?.action === SmartLinkActions.UPDATE && !!value?.data?.length) {
        const newSmartLinkElement: any = targetElement.cloneNode(true);
        newSmartLinkElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(value.data)));
        this.topic.description = this.topic?.description?.replace(targetElement.outerHTML, newSmartLinkElement.outerHTML);
      }
      if (
        value?.action === SmartLinkActions.DELETE ||
        (!!value?.data && !value?.data?.length &&
          value?.action !== SmartLinkActions.ADD_HIGHLIGHT &&
          value?.action !== SmartLinkActions.ADD_STICKY_NOTE &&
          value?.action !== SmartLinkActions.ADD_REFERENCE)
      ) {
        this.topic.description = this.topic?.description?.replace(targetElement.outerHTML, targetElement.textContent);
      }
      if (value?.action === SmartLinkActions.ADD_HIGHLIGHT) {
        const newElement = this._keywordService.addKeywordToExistingSmartString(targetElement, value?.data);
        this.topic.description = this.topic?.description?.replace(targetElement.outerHTML, newElement.outerHTML);
      }
      if (value?.action === SmartLinkActions.ADD_STICKY_NOTE) {
        const newElement = this._stickyNoteService.addStickyNoteToExistingSmartString(targetElement, value?.data);
        this.topic.description = this.topic?.description?.replace(targetElement.outerHTML, newElement.outerHTML);
        this.topic.sticky_notes_attributes = Utils.convertJsonArrayToJson(value?.data?.stickyNotesData);
      }
      if (value?.action === SmartLinkActions.ADD_REFERENCE) {
        const newElement = this._remarksService.addReferenceToExistingSmartString(targetElement, value?.data);
        this.topic.description = this.topic?.description?.replace(targetElement.outerHTML, newElement.outerHTML);
        this.topic.remarks_attributes = Utils.convertJsonArrayToJson(value?.data?.referencesData);
      }
      if (
        value?.action === SmartLinkActions.UPDATE ||
        value?.action === SmartLinkActions.DELETE ||
        value?.action === SmartLinkActions.ADD_HIGHLIGHT ||
        value?.action === SmartLinkActions.ADD_STICKY_NOTE ||
        value?.action === SmartLinkActions.ADD_REFERENCE) {
        this.translate.get("UPDATING_THE_TOPIC").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
        this.channelRepository.updateTopic(this.topicId, this.topic).pipe(take(1)).subscribe(() => {
          this.translate.get("TOPIC_UPDATED").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
          this.updateReferenceIndices();
        });
      }
    }, 100);
  }

  onTopicDetailClick(event: any, mobileMode = false) {
    const anchor = event.target.closest("a[href^='http']");
    if (anchor && environment.isElectron) {
      event.preventDefault();
      this.electronService.openExternalUrl(anchor.href);
    } else if (CommonUtil.isOnCordova()) {
      event.preventDefault();
      cordova.InAppBrowser.open(anchor.href, "_system");
    }

    const targetElement: Element = event.target;
    const getAttribute = (element, attributeName) => element.getAttribute(attributeName);
    if (targetElement?.tagName === "S" && getAttribute(targetElement, "data-type") === SmartObject.SMART_OBJECTS) {
      this.openSmartObjectsPreview(event, false, false, mobileMode);
      return;
    }
    if (targetElement?.tagName === "S" && getAttribute(targetElement, "data-type") === SmartObject.HIGHLIGHT.toLowerCase()) {
      this.openSmartObjectsPreview(event, true, false, mobileMode);
      return;
    }
    if (targetElement?.tagName === "S" && getAttribute(targetElement, "data-type") === SmartObject.REFERENCES.toLowerCase()) {
      this.openSmartObjectsPreview(event, false, true, mobileMode);
      return;
    }

    if (targetElement?.tagName === "S" && getAttribute(targetElement, "data-type") === SmartObject.SMART_LINK.toLowerCase()) {
      this.openSmartLinkPreview(event, mobileMode);
      return;
    }

    if (targetElement?.tagName === "S" && getAttribute(targetElement, "data-type") === "sticky-note") {
      const data = {
        uid: getAttribute(targetElement, "data-uid"),
        clientX: event.clientX,
        clientY: event.clientY,
      };
      this.stickyNoteClicked(data, mobileMode);
      return;
    }
    if (targetElement?.tagName === "S" && getAttribute(targetElement, "data-type") === "remark-index") {
      const data = {
        uid: event.target.getAttribute("data-remark-id"),
        clientX: event.clientX,
        clientY: event.clientY,
      };
      this.remarkClicked(data);
      return;
    }

    const { tagId, tagName } = this.getTagIdAndName(targetElement);

    if (!tagId || !tagName) {
      return;
    }

    this.broadcaster.broadcast(ConstantsUtil.INITIATE_TAG_BASED_SEARCH, { tagId, tagName });
  }


  async openSmartLinkPreview(event, mobileMode = false) {
   if(this.isMobileScreen) {
    const targetElement: Element = event.target;

    const data = {
      smartLinkData: await this._smartLinkService
        .getSmartLinkData(targetElement)
        .pipe(take(1))
        .toPromise(),
      smartLinkName: targetElement.textContent,
      isEditable: this.topic?.id
          ? !!(this.topic as Topic)?.permissions?.find((item) =>
            item.hasOwnProperty("editable")
          )?.editable
          : true,
      shouldNavigate: false,
      isAnalyticsTopic: this.topic?.enableAnalyticsMode,
      isMobileView: mobileMode
    };
    // setTimeout(async () => {
    //   const smartLinkPreviewRef = this._smartLinkService.getSmartLinkPreviewRef(
    //     data,
    //     event?.clientX,
    //     event?.clientY,
    //     true
    //   );
    //   const value = await smartLinkPreviewRef
    //     .afterClosed()
    //     .pipe(take(1))
    //     .toPromise();
    //
    //
    // }, 100);
    setTimeout(async () => {
      const smartLinkPreviewRef = this._smartLinkService.getSmartLinkPreviewRef(data, event?.clientX, event?.clientY, true);
      smartLinkPreviewRef.componentInstance.$updateSmartlinkData.pipe(take(1)).subscribe(res => {
        const newSmartLinkElement: any = targetElement.cloneNode(true);
        newSmartLinkElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(res.data)));
        this.topic.description = this.topic.description.replace(targetElement.outerHTML, newSmartLinkElement.outerHTML);

        this.translate.get("UPDATING_THE_TOPIC").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
        this.channelRepository.updateTopic(this.topicId, this.topic).pipe(take(1)).subscribe(() => {
          this.translate.get("TOPIC_UPDATED").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
          this.changeDetectorRef.markForCheck();
        });
      });
      const value = await smartLinkPreviewRef.afterClosed().pipe(take(1)).toPromise();
      this.$previewAlreadyOpen.next(false);

      if (value?.action === SmartLinkActions.UPDATE && !!value?.data?.length) {
        const newSmartLinkElement: any = targetElement.cloneNode(true);
        newSmartLinkElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(value.data)));
        this.topic.description = this.topic.description.replace(targetElement.outerHTML, newSmartLinkElement.outerHTML);
      }
      if (
        value?.action === SmartLinkActions.DELETE ||
        (!!value?.data && !value?.data?.length &&
          value?.action !== SmartLinkActions.ADD_HIGHLIGHT &&
          value?.action !== SmartLinkActions.ADD_STICKY_NOTE &&
          value?.action !== SmartLinkActions.ADD_REFERENCE)
      ) {
        this.topic.description = this.topic.description.replace(targetElement.outerHTML, targetElement.textContent);
      }
      if (value?.action === SmartLinkActions.ADD_HIGHLIGHT) {
        const newElement = this._keywordService.addKeywordToExistingSmartString(targetElement, value?.data);
        this.topic.description = this.topic.description.replace(targetElement.outerHTML, newElement.outerHTML);
      }
      if (value?.action === SmartLinkActions.ADD_STICKY_NOTE) {
        const newElement = this._stickyNoteService.addStickyNoteToExistingSmartString(targetElement, value?.data);
        this.topic.description = this.topic.description.replace(targetElement.outerHTML, newElement.outerHTML);
        this.topic.sticky_notes_attributes = Utils.convertJsonArrayToJson(value?.data?.stickyNotesData);
      }
      if (value?.action === SmartLinkActions.ADD_REFERENCE) {
        const newElement = this._remarksService.addReferenceToExistingSmartString(targetElement, value?.data);
        this.topic.description = this.topic.description.replace(targetElement.outerHTML, newElement.outerHTML);
        this.topic.remarks_attributes = Utils.convertJsonArrayToJson(value?.data?.referencesData);
      }
      if (
        value?.action === SmartLinkActions.UPDATE ||
        value?.action === SmartLinkActions.DELETE ||
        value?.action === SmartLinkActions.ADD_HIGHLIGHT ||
        value?.action === SmartLinkActions.ADD_STICKY_NOTE ||
        value?.action === SmartLinkActions.ADD_REFERENCE) {
        this.translate.get("UPDATING_THE_TOPIC").pipe(take(1)).subscribe(text => {
          this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
        });
        this.channelRepository.updateTopic(this.topicId, this.topic).pipe(take(1)).subscribe(() => {
          this.translate.get("TOPIC_UPDATED").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
          this.updateReferenceIndices();
        });
      }
    }, 100);
   }
  }

  getTagIdAndName(element: Element) {
    if (element.tagName !== "SPAN") {
      return {
        tagId: null,
        tagName: null
      };
    }
    if (element?.tagName === "SPAN" && element?.getAttribute("data-denotation-char") === "#") {
      return {
        tagId: element?.getAttribute("data-id"),
        tagName: element?.getAttribute("data-value")
      };
    }
    if (element?.tagName === "SPAN" && element.getAttribute("contenteditable") === "false") {
      return this.getTagIdAndName(element.parentElement);
    }
    else
    {
      return{
        tagId: null,
        tagName: null
      };
    }
  }

  async openSmartObjectsPreview(event, hasOnlyHighlight = false, hasOnlyReference = false, mobileMode = false) {

      const targetElement: Element = event.target;
      const getAttribute = (element, attributeName) => element.getAttribute(attributeName);

      let popupData: any = {};
      let previewData: any = {};

      if (!hasOnlyHighlight && !hasOnlyReference) {
        previewData = JSON.parse(decodeURIComponent(getAttribute(targetElement, "data-preview-data")));
        let smartLinkData = [];
        if (getAttribute(targetElement, "data-smartlink") === "true" && previewData?.smartLinkData?.length > 0) {
          const { smartLinkData: smartLinkPreviewData } = previewData;
          smartLinkData = await this._smartLinkService.getSmartLinkData(targetElement, smartLinkPreviewData).pipe(take(1)).toPromise();
        }

        let stickyNotesData = [];
        if (previewData?.stickyNotesData?.length > 0 && this.topic?.sticky_notes?.length > 0) {
          stickyNotesData = this.topic?.sticky_notes.filter(note => previewData?.stickyNotesData.includes(note?.uid));
        }

        let referencesData = [];
        let referenceIndexes = {};
        if (previewData?.referencesData?.length > 0 && this.topic?.remarks?.length > 0) {
          referencesData = this.topic?.remarks.filter(note => previewData?.referencesData.includes(note?.uid));
          const referenceIndices = targetElement.parentElement.querySelectorAll("s[data-type=\"remark-index\"]");
          referenceIndices.forEach(index => {
            const id = index.getAttribute("data-remark-id");
            referenceIndexes[id] = index.textContent.substring(1, index.textContent.length - 1);
          });
        }

        popupData = {
          ...previewData,
          smartLinkData,
          stickyNotesData,
          referencesData,
          referenceIndexes
        };
      }

      hasOnlyHighlight ? popupData = {
        highlightData: {
          name: targetElement?.textContent
        }
      } : noop();

      if (hasOnlyReference) {
        const referenceIds = [getAttribute(targetElement, "data-uid")];
        const index = targetElement.querySelector("s[data-type=\"remark-index\"]")?.textContent;
        popupData = {
          referencesData: this.topic?.remarks.filter(remark => referenceIds.includes(remark?.uid)),
          referenceIndexes: {
            [referenceIds[0]]: index?.slice(1, index.length - 1)
          }
        };
      }

      const isEditable = !!this.topic?.permissions?.find(item => item?.hasOwnProperty("editable"))?.editable;

      let { clientX, clientY } = event;
      if (clientX === 0 && clientY === 0) {
        clientX = event?.target?.firstChild?.x;
        clientY = event?.target?.firstChild?.y;
      }

      const linkText = hasOnlyReference ? targetElement.childNodes?.[1]?.textContent : targetElement.textContent;

      const smartObjectsPreviewRef = this._smartObjectsService.getSmartObjectsPreviewRef(popupData, linkText, isEditable, true, clientX, clientY, mobileMode);

      const value = await smartObjectsPreviewRef.afterClosed().pipe(take(1)).toPromise();

      // AFTER CLOSED

      setTimeout(() => {

        if (value?.action === SmartObjectActions.UPDATE && Object.keys(value?.data)?.length > 0) {
          let fullData = { ...{ ...value }?.data };
          const newSmartObjectElement: any = ((hasOnlyHighlight || hasOnlyReference) && Object.keys(fullData)?.length > 0) ? this._smartObjectsService.getNewSmartObjectNode() : targetElement.cloneNode(true);
          hasOnlyReference ?
            newSmartObjectElement.textContent = linkText : noop();

          if (fullData.hasOwnProperty("smartLinkData") && fullData?.smartLinkData?.length > 0) {
            const mappedSmartLinkMetaData = this._smartLinkService.getMappedSmartLinkMetaData(fullData?.smartLinkData);

            fullData = {
              ...fullData,
              smartLinkData: mappedSmartLinkMetaData
            };
          }

          if (fullData.hasOwnProperty("highlightData") && !!fullData?.highlightData?.name) {
            newSmartObjectElement.textContent = fullData?.highlightData?.name;
          }

          const newStickyNotes = (fullData?.stickyNotesData || [])?.filter(note => !previewData?.stickyNotesData?.includes(note?.uid));

          if (
            fullData.hasOwnProperty("stickyNotesData") &&
            fullData?.stickyNotesData?.length > 0
          ) {
            newStickyNotes?.length > 0 ?
              this.topic.sticky_notes_attributes = Utils.convertJsonArrayToJson(newStickyNotes) : noop();
            fullData.stickyNotesData = [
              ...(fullData.stickyNotesData.map(note => note?.uid))
            ];
          }

          let newReferences = (fullData?.referencesData || [])?.filter(note => !previewData?.referencesData?.includes(note?.uid));
          hasOnlyReference && newReferences.length > 0 ?
            newReferences = newReferences.filter(ref => ref.uid !== getAttribute(targetElement, "data-uid")) : noop();

          if (
            fullData.hasOwnProperty("referencesData") &&
            fullData?.referencesData?.length > 0
          ) {
            newReferences?.length > 0 ?
              this.topic.remarks_attributes = Utils.convertJsonArrayToJson(newReferences) : noop();
            fullData.referencesData = [
              ...(fullData.referencesData.map(note => note?.uid))
            ];
          }

          const { existingObjects, availability } = this._smartObjectsService.checkForExistingObjects(fullData);

          Object.entries(availability).forEach(entry => {
            if (!!entry[1]) {
              newSmartObjectElement.setAttribute(`data-${entry[0].replace("_", "-")}`, "true");
            } else {
              newSmartObjectElement.removeAttribute(`data-${entry[0].replace("_", "-")}`);
            }
          });

          let removePreviewData = false;
          if (existingObjects?.length === 1) {
            removePreviewData = true;
            if (existingObjects[0] === "remark" && fullData?.referencesData?.length === 1) {
              const uid = { ...fullData }?.referencesData?.[0];
              newSmartObjectElement.setAttribute("data-type", existingObjects[0]);
              newSmartObjectElement.removeAttribute(`data-${existingObjects[0]}`);
              this._smartObjectsService.removeIconsFromNode(targetElement);
              this._smartObjectsService.removeIconsFromNode(newSmartObjectElement);
              newSmartObjectElement.setAttribute("data-uid", uid);
              newSmartObjectElement.className = "remark-text";
              newSmartObjectElement.prepend(this._remarksService.getRemarkIndexElement(uid));
            } else if (existingObjects[0] === "remark" && fullData?.referencesData?.length > 1) {
              removePreviewData = false;
            }

            if (existingObjects[0] === "smartlink") {
              fullData = fullData.smartLinkData;
              removePreviewData = false;
            }
            if (existingObjects[0] === "sticky-note" && fullData?.stickyNotesData?.length === 1) {
              let noteData = this.topic.sticky_notes.filter(note => note?.uid === fullData.stickyNotesData[0]);
              noteData?.length === 0 ?
                noteData = this.topic.sticky_notes_attributes.filter(note => note?.uid === fullData.stickyNotesData[0]) : noop();
              newSmartObjectElement.setAttribute("data-sticky-note-bg-color", this._stickyNoteService.getColorSchemeByBgColor(noteData[0]?.bg_color)?.name);
              newSmartObjectElement.setAttribute("data-uid", noteData[0]?.uid);
            } else if (existingObjects[0] === "sticky-note" && fullData?.stickyNotesData?.length > 1) {
              removePreviewData = false;
            }

            if (existingObjects[0] === "keyword") {
              newSmartObjectElement.setAttribute("data-type", existingObjects[0]);
              newSmartObjectElement.removeAttribute(`data-${existingObjects[0]}`);
            }
          }
          removePreviewData ?
            newSmartObjectElement.removeAttribute("data-preview-data") :
            newSmartObjectElement.setAttribute("data-preview-data", encodeURIComponent(JSON.stringify(fullData)));
          targetElement.removeAttribute("data-icon-set");
          newSmartObjectElement.removeAttribute("data-icon-set");
          this.topic.description = this.removeSmartObjectIconAndReferenceIndices(this.topic.description, true);
          this.removeSmartObjectIconAndReferenceIndices(targetElement);
          this.removeSmartObjectIconAndReferenceIndices(newSmartObjectElement);
          this.topic.description = this.topic.description.replace(targetElement.outerHTML, newSmartObjectElement.outerHTML);
        }

        if (
          value?.action === SmartObjectActions.DELETE ||
          (!!value?.data && Object.keys(value?.data)?.length === 0)
        ) {
          const outerHTML = targetElement.outerHTML;
          targetElement.querySelectorAll(".smart-object-icon").forEach(img => img.remove());
          hasOnlyReference ?
            targetElement.querySelectorAll(".remark-index").forEach(index => index.remove()) : noop();
          targetElement.removeAttribute("data-icon-set");
          this.topic.description = this.topic.description.replace(outerHTML, targetElement.textContent);
        }

        if (
          value?.action === SmartObjectActions.UPDATE ||
          value?.action === SmartObjectActions.DELETE) {
          this.translate.get("UPDATING_THE_TOPIC").pipe(take(1)).subscribe(text => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
          });
          this.channelRepository.updateTopic(this.topicId, this.topic).pipe(take(1)).subscribe(() => {
            this._smartObjectsService.updateSmartObjectIcons(this.getTopicDetailWrapperId());
            this.translate.get("TOPIC_UPDATED").pipe(take(1)).subscribe(text => {
              this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
            });
            this.updateReferenceIndices();
            if (value?.action === SmartObjectActions.DELETE) {
              if (hasOnlyHighlight) {
                this._smartObjectsService.showSnackBar("HIGHLIGHT_DELETED", SnackbarType.CHECKMARK);
              } else if (hasOnlyReference) {
                this._smartObjectsService.showSnackBar("REFERENCE_DELETED", SnackbarType.CHECKMARK);
              } else {
                this._smartObjectsService.showSnackBar("SMART_OBJECT_DELETED_SUCCESSFULLY", SnackbarType.CHECKMARK);
              }
            } else {
              this._smartObjectsService.showSnackBar("SMART_OBJECT_UPDATED_SUCCESSFULLY", SnackbarType.CHECKMARK);
            }
          });
        }
      }, 1000);

  }

  removeSmartObjectIconAndReferenceIndices(content, shouldBeParsed = false) {
    let parsedContent = content;
    if (shouldBeParsed) {
      parsedContent = new DOMParser().parseFromString(content, "text/html")?.body;
    }
    parsedContent.querySelectorAll(".smart-object-icon").forEach(icon => icon.remove());
    parsedContent.querySelectorAll("s[data-type=\"remark-index\"]").forEach(index => index.textContent = "");
    parsedContent.querySelectorAll("s[data-icon-set=\"true\"]").forEach(element => element.removeAttribute("data-icon-set"));
    parsedContent.querySelectorAll("s[data-icon-set=\"false\"]").forEach(element => element.removeAttribute("data-icon-set"));
    parsedContent.querySelectorAll("s[data-icon-set=\"\"]").forEach(element => element.removeAttribute("data-icon-set"));
    return shouldBeParsed ?
      parsedContent.outerHTML.substring(6, parsedContent.outerHTML.length - 7) : // remove body tag
      parsedContent.outerHTML;
  }

  goToParentActionClick(event) {
    if (this.isMobileScreen) {
      return this.router.navigate([`/talk/channels/${this.parentChannelId}/topics/${this.parentTopicId}`]).then();
    }
    if (event.target.className.localeCompare("text-close-label") === 0) {
      this.dismissGotoBanner();
      this.router.navigate([`/talk/channels/${this.parentChannelId}/topics/${this.parentTopicId}`]).then();
    }
  }

  dismissGotoBanner() {
    this.$showGoToParentBanner.next(false);
  }

  mobileLikeUnlike(): void {
    const item = this.topic;
    if (item && item.liked) {
      this.channelRepository.unlikesTopic(item.id);
    } else {
      this.channelRepository.likesTopic(item.id);
    }
  }

  addComment(): void {
    setTimeout(() => {
      const topicCommentSection = document.querySelector(".mobile-topic-detail-component");
      const commentpart: any = document.querySelector(".topic-comment-part");
      if (topicCommentSection !== null && commentpart !== null) {
        topicCommentSection.scrollTo({
          top: commentpart.offsetTop,
          behavior: "smooth"
        });
      }
    }, 300);
  }

  shareTopic(item: any): void {
    let currentUrl = CommonUtil.getBaseOriginUrl();
    currentUrl = `${currentUrl}/talk/channels/${item.channel_id}/topics/${item.id}`;
    this.channelRepository.shareLink(currentUrl);
  }

  backToChannelTab(): void {
    this.backToChannel();
    setTimeout(() => {
      this.broadcaster.broadcast("showLeftPanel");
      if (document.getElementById("mainLayout") !== null) {
        document.getElementById("mainLayout").classList.remove("hide-header-mobile");
        document.getElementById("mainLayout").classList.remove("hide-header-search-form");
      }
    }, 50);
  }

  async cloneTopic() {
    // this.channelRepository.cloneTopic(this.topic.id);
    this.channelDetailService.cloneTheTopicNew(this.topic);
  }

  getPermission(permissions: any[] | null = null, toCheck: string) {
    return CommonUtil.getPermission(permissions, toCheck);
  }

  async editScheduleTopic() {
    const options = {
      width: "500px",
      height: "300px"
    };
    const { EditScheduleDialogComponent } = await import(
      "../edit-schedule-dialog/edit-schedule-dialog.component");
    this.matDialog.open(EditScheduleDialogComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: [ "vnctalk-form-panel", "vnctalk-channel-edit-schedule-dialog"],
      data: this.topic,
      autoFocus: false
    }, options));
  }

  async publishDraftTopic() {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    if (CommonUtil.isMobileSize()) {
      options = {
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "300px"
      };
    }
    const { ConfirmationChannelComponent } = await import(
      "../confirmation-channel/confirmation-channel.component");
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: ["vnctalk-form-panel", "confirmation-dialog"],
      disableClose: true,
      data: {
        headerText: "PUBLISH_TOPIC_HEADER",
        bodyText: "PUBLISH_TOPIC_DESCRIPTION",
        okLabel: "YES",
        cancelLabel: "CANCEL"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          const body = {
            "is_draft": "0"
          };
          this.channelRepository.updateTopic(this.topic.id, body).pipe(take(1)).subscribe( (res: any) => {
            if (res?.description && res?.channel_id && res?.id) {
              this.sendMentionNotifications(res.description, res.channel_id, res.id);
            }
            this.broadcaster.broadcast(BroadcastKeys.FETCH_CHANNEL_MEMBERS);
            this.broadcaster.broadcast(BroadcastKeys.FETCH_CHANNEL_FILES);
            this.broadcaster.broadcast(BroadcastKeys.FETCH_TOPIC_FILES);
            this.translate.get("TOPIC_PUBLISHED").pipe(take(1)).subscribe(text => {
              this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
            });
          });
        }
      }
    });
  }

  private sendMentionNotifications(text: string, channelId: string, topicId: string) {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(text, "text/html");
    const users = htmlDoc.getElementsByClassName("mention");
    const userIds = Array.from(users).map( user => user.getAttribute("data-id"));

    if (userIds?.length > 0) {
      const currentUrl = CommonUtil.getBaseOriginUrl();
      userIds?.forEach((jId) => {
        const type = jId.includes("@conference") ? "groupchat" : "chat";
        this.conversationRepo.sendMessage({
          body: `You are mentioned in this topic: ${currentUrl}/talk/channels/${channelId}/topics/${topicId}`,
          type: type
        }, jId, type);
      });
    }
  }

  private renameFile(updatedFile: File): void {
    const files = this.$topicFiles.getValue();
    const fileToBeChanged = files.findIndex(f => f?.id === updatedFile?.id);
    const newFiles = [...files.slice(0, fileToBeChanged), {...updatedFile, filename: !!updatedFile?.description ? updatedFile?.description : updatedFile?.filename}, ...files.slice(fileToBeChanged + 1)];
    this.$topicFiles.next(newFiles);
  }

  onNewRelatedTopicAdded(topic) {
    this.channelRepository.updateTopicRelations(this.topicId, [topic?.id]).pipe(take(1))
      .subscribe();
  }

  onRemoveRelatedTopics(ids) {
    this.channelRepository.removeRelatedTopic(this.topicId, ids);
  }

  async onSendRelatedTopics(relatedTopics) {
    let width = "480px";
    let height = "572px";
    let maxWidth = "80vw";
    if (CommonUtil.isMobileSize()) {
      width = "100vw";
      height = "100vh";
      maxWidth = "100vw";
    }

    const sendTopicLinkRef = this.matDialog.open(SendTopicLinkComponent, {
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      width: width,
      height: height,
      maxWidth: maxWidth,
      autoFocus: true,
      data: {}
    });
    sendTopicLinkRef.afterClosed()
      .pipe(takeUntil(this.isAlive$))
      .subscribe( (val: any)=> {
        if (val?.selectedUserIds?.length > 0) {
          let comment = `Hey, checkout ${relatedTopics.length > 1 ? "these topics" : "this topic"}`;
          this._translate.get("HEY_CHECKOUT_THIS_TOPIC").pipe(take(1)).subscribe((text: string) => {
            comment = text;
          });
          if (!!val?.comment) {
            comment = val.comment;
          }
          const currentUrl = CommonUtil.getBaseOriginUrl();
          let msgBody = "";
          if (!!relatedTopics?.topics) {
            relatedTopics?.topics?.forEach((relatedTopic) => {
              if(!!relatedTopic) {
                msgBody += `<br>${currentUrl}/talk/channels/${relatedTopic?.full_data?.channel_id}/topics/${relatedTopic?.id}`;
              }
            });
          } else {
            relatedTopics?.forEach((relatedTopic) => {
              if(relatedTopic) {
                msgBody += `<br>${currentUrl}/talk/channels/${relatedTopic?.full_data?.channel_id}/topics/${relatedTopic?.id}`;
              }
            });
          }
          val?.selectedUserIds?.forEach((jId) => {
            const type = jId.includes("@conference") ? "groupchat" : "chat";
            this.conversationRepo.sendMessage({
              body: `${comment} : ${msgBody}`,
              type: type
            }, jId, type);
          });
          this.translate.get("LINK_SENT").pipe(take(1)).subscribe((text: string) => {
            this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
          });
        }
      });
  }

  remarkClicked(remark){
    document.getElementById(this.getTopicDetailWrapperId())?.querySelectorAll(`s[data-remark-id="${remark.uid}"]`)?.item(0)?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  togglePlayState() {
    if (this.videoPlayState === "play") {
      this.videoPlayState = "paused";
      this.mobileTopicVideoPlayer?.nativeElement?.play();
    } else {
      this.videoPlayState = "play";
      this.mobileTopicVideoPlayer?.nativeElement?.pause();
    }
  }

  videoEnded() {
    this.videoPlayState = "play";
  }

  playMobileVideoFromCover(topic): void {
    // this.channelRepository.showFilePreview(topic?.heroAttachments, true);
  }

  stickyNoteClicked(data, mobileMode = false) {
    const note = this.stickyNotes.filter((stickyNote)=>stickyNote.uid == data.uid)[0];
    setTimeout(()=>{
      const stickyNoteRef = this._stickyNoteService.getStickyNotePopupRef(data.uid,data.clientX,data.clientY,this._stickyNoteService.transformStickyNoteData(note),true, false, false, mobileMode);
      if (mobileMode) {
        stickyNoteRef.afterClosed().pipe(take(1)).subscribe(res => {
          const dialogCloseRes = res;
          let changedNote: any = {};
          if (!!res) {
            switch (res.action) {
              case "DELETE":
                const stickyNote = document.querySelectorAll(`s[data-uid="${data.uid}"]`).item(0);
                this.topic.description = this.topic.description.replace(stickyNote.outerHTML, stickyNote.innerHTML);
                break;

              case "SAVE":
                const { noteData } = res;
                this.stickyNotes = this.stickyNotes.map(k=>{
                  if(k.uid === noteData.uid) {
                    k.bg_color = noteData.bg_color_hex.substring(1);
                    k.notes = noteData.note_description;
                    changedNote = k;
                  }
                  return k;
                });
                const targetElement = document.querySelectorAll(`s[data-uid="${data.uid}"]`).item(0);
                const newElement = this._stickyNoteService.constructStickyNoteData(targetElement.innerHTML, changedNote.uid, changedNote.bg_color).outerHTML;
                this.topic.description = this.topic.description.replace(targetElement.outerHTML, newElement);
                this.topic.sticky_notes_attributes = this.stickyNotes;
                break;
            }

            this.translate.get("UPDATING_THE_TOPIC").pipe(take(1)).subscribe(text => {
              this.channelSnackBarService.openSnackBar(text, SnackbarType.CLOSE);
            });
            this.channelRepository.updateTopic(this.topicId, this.topic).pipe(take(1)).subscribe(() => {
              if (dialogCloseRes.action === "DELETE") {
                this._stickyNoteService.deleteStickyNote(data.uid);
              }
              if (dialogCloseRes.action === "SAVE") {
                this._stickyNoteService.changeStickyNoteDataInHTML(changedNote);
              }
              this.translate.get("TOPIC_UPDATED").pipe(take(1)).subscribe(text => {
                this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
              });
            });
          }
        });
      }
    },500);
  }

  updateReferenceIndices(updateIcons = true) {
    setTimeout(() => {
      this._remarksService.removeIndicesFromTopicDetailPage(this.getTopicDetailWrapperId());
      this._remarksService.addFreshIndicesToTopicDetailPage(this.getTopicDetailWrapperId());
      this._remarksService.updateQuillRemarkIndexes(this.topic.remarks, this.getTopicDetailWrapperId());
      updateIcons ? this._smartObjectsService.updateSmartObjectIcons(this.getTopicDetailWrapperId()) : noop();
      this.changeDetectorRef.markForCheck();
    }, 1000);
  }

  getTopicDetailWrapperId() {
    return this.isMobileScreen ? "mobile_topic_detail_body" : "topic_detail_body";
  }

  bindInlineImageClickEvents() {
    setTimeout(() => {
      let htmlDoc = document.querySelector(".description-info") || document.querySelector(".description");
      if (!!htmlDoc) {
        let images = htmlDoc.getElementsByTagName("img");
        if (images && images.length > 0) {
          for (let i = 0; i < images.length; i++) {
            images[i].addEventListener("click", (event: Event) => this.onclickEvent(event), false);
          }
        }
      }

    }, 1000);
  }

  private onclickEvent(event) {
    if (event?.target?.src) {
      let url = event?.target?.src;
      let imageId = url.slice(url.lastIndexOf("\/") + 1, url.indexOf("?"));
      if (imageId) {
        let attachments = this.topic.attachments.filter(attachment => attachment.is_inline);
        let attachment = attachments.filter(attachment => attachment.id == imageId)[0];
        if (attachment) {
          attachments = attachments.map(attachment => { return {...attachment, deletable: false };});
          this.channelRepository.showFilePreview(attachments, attachment, false, this.topic?.is_iom);
        }
      }
    }
  }

  getVideoId(url: string) {
    let tempUrl = url.split("/");
    let videoId = tempUrl[tempUrl.length - 1];
    if (videoId.includes("?si=")) {
      videoId = videoId.split("?")[0];
    } else if (videoId.includes("watch?v=")) {
      videoId = videoId.replace("watch?v=", "");
    }
    return videoId;
  }

  playYoutube(url: string) {
    if (this.electronService.isElectron) {
      this.electronService.openExternalUrl(url);
    } else if (CommonUtil.isOnNativeMobileDevice()) {
      if (device.platform === "iOS" || CommonUtil.isOnIOSMobile()) {
        window.open(url, "_system");
      } else if (device.platform === "Android" || CommonUtil.isOnAndroid()) {
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    }
    else {
      window.open(url, "_blank");
    }
  }

  removeAllTags() {
    this.topic.tags = [];
    this.channelRepository.updateTopic(this.topicId, {"tag_list" : ""}).pipe(take(1)).subscribe(() => {
      this.translate.get("TOPIC_UPDATED").pipe(take(1)).subscribe(text => {
        this.channelSnackBarService.openSnackBar(text, SnackbarType.CHECKMARK);
      });
      this.changeDetectorRef.markForCheck();
    });
  }

  restoreTopicOnConfirmation(item: Topic) {
    let options: any = {
      width: "480px",
      height: "280px"
    };
    this.matDialog.open(ConfirmationChannelComponent, Object.assign({
      backdropClass: "vnctalk-form-backdrop",
      panelClass: "vnctalk-form-panel",
      disableClose: true,
      data: {
        headerText: item?.deleted ? "RESTORE_TOPIC" : "UNARCHIVE_TOPIC",
        bodyText: `Do you want to ${item?.deleted ? "restore" : "unarchive"} <b>${item.subject}</b> topic?`,
        okLabel: item?.deleted ? "RESTORE" : "UNARCHIVE"
      },
      autoFocus: true
    }, options)).afterClosed().pipe(take(1)).subscribe( (res: any) => {
      if (!!res && res.confirmation) {
        if (res.confirmation === "yes") {
          if (item?.deleted) {
            this.restoreTopic(item);
            this.topicRestored = true;
            this.restoreBannerLabel = "TOPIC_IS_RESTORED";
          }
          if (item?.archived) {
            this.channelRepository.unarchiveTopic(item.id).pipe(take(1)).subscribe(_ => {
              this.topicUnarchived = true;
              this.restoreBannerLabel = "TOPIC_UNARCHIVED";
            });
          }
        }
      }
    });
  }
  goBackToTrashOrArchive(event, data: any) {
    let component;
    if (data?.trash) {
      component = TrashWindowComponent;
    }
    if (data?.archive) {
      component = ArchiveWindowComponent;
    }
    if (event?.target?.className?.localeCompare("text-close-label") === 0 || !event) {
      // this.router.navigate(["/"]);
      this.archiveWindowServifce.changeTab("topics");
      this.matDialog.open(component, {
        backdropClass: "archive-contacts-backdrop",
        panelClass: "mobile-dialog",
        width: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        maxWidth: "100vw",
        autoFocus: true,
        data: {isMobileScreen: true, setCategory: "topics"}
      });
    }
  }
}
