import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AvatarComponent } from "./talk/shared/components";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [AvatarComponent],
    imports: [ CommonModule, MatIconModule ],
    exports: [AvatarComponent],
    providers: [],
})
export class GlobalSharedModule {}