import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { VirtualScrollerModule } from "ngx-virtual-scroller";
import { TalkSharedModule } from "../shared/shared.module";
import { VideoConferenceSettingsComponent } from "./all-settings/all-settings.component";
import { ParticipantTileComponent } from "./participant-tile/participant-tile.component";
import { ParticipantsListComponent } from "./participants-list/participants-list.component";
import { RecordingCompletedPopupComponent } from "./recording-completed-popup/recording-completed-popup.component";
import { SendBroadcastComponent } from "./send-broadcast/send-broadcast.component";
import { SendMessageMiniComponent } from "./send-message-mini/send-message-mini.component";
import { TilesViewComponent } from "./tiles-view/tiles-view.component";
import { VNCTalkVideoConferenceComponent } from "./video-conference.component";
import { WhiteboardConferenceComponent } from "./whiteboard/whiteboard.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { GlobalSharedModule } from "app/global-shared.module";
import { VncLibraryModule } from "vnc-library";
import { QuickCallInfoComponent } from "./quick-call-info/quick-call-info.component";

@NgModule({
    declarations: [
        VNCTalkVideoConferenceComponent,
        WhiteboardConferenceComponent,
        TilesViewComponent,
        SendMessageMiniComponent,
        SendBroadcastComponent,
        RecordingCompletedPopupComponent,
        ParticipantsListComponent,
        ParticipantTileComponent,
        QuickCallInfoComponent,
        VideoConferenceSettingsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatMenuModule,
        MatGridListModule,
        ScrollingModule,
        VirtualScrollerModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        VncLibraryModule,
        GlobalSharedModule,
        TalkSharedModule ],
    exports: [
        VNCTalkVideoConferenceComponent,
        WhiteboardConferenceComponent,
        TilesViewComponent,
        SendMessageMiniComponent,
        SendBroadcastComponent,
        RecordingCompletedPopupComponent,
        ParticipantsListComponent,
        ParticipantTileComponent,
        QuickCallInfoComponent,
        VideoConferenceSettingsComponent
    ],
    providers: [],
})
export class VideoConferenceModule {}
