
/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy,
  Output, ElementRef, ViewChild, EventEmitter, OnChanges, SimpleChanges, TemplateRef } from "@angular/core";
import { Store } from "@ngrx/store";
import { getBlockedBareIds, getConversationById, TalkRootState } from "../../../reducers";
import { getContactStatusById, getDomain, getLastPhotoUpdate } from "../../../../reducers";
import { ContactRepository } from "../../../repositories/contact.repository";
import { getUserStatusType } from "../../../../shared/models/index";
import { Subject } from "rxjs";
import { CommonUtil } from "../../../utils/common.util";
import { ConfigService } from "app/config.service";
import { AvatarRepository } from "app/talk/repositories/avatar.repository";
import { environment } from "app/environments/environment";
import { distinctUntilChanged, filter, map, take, takeUntil, throttleTime } from "rxjs/operators";
import { LoggerService } from "app/shared/services/logger.service";
import { getIsAppOnline } from "../../../../reducers/index";
import { Conversation } from "vnc-library/lib/models/conversation.model";

@Component({
  selector: "vp-avatar",
  templateUrl: "./avatar.html",
  changeDetection: ChangeDetectionStrategy.OnPush   // TODO https://redmine.vnc.biz/issues/30003052-684
})
export class AvatarComponent implements OnDestroy, OnChanges {
  avatarUrl: any;
  isAvatarExists = false;

  status: string;
  isHinTheme = environment.theme === "hin";
  @Input() size: string;
  @Input() icon: string;
  @Input() background: string;
  @Input() disableTooltip: string;
  @Output() bgcolor: string;
  @Output() text: string;
  @Input() hidestatus: boolean;
  @Input() hideWhileImageLoading: boolean;
  @Input() hideBlockedIcon: boolean;
  @Input() isGroupChat: boolean;
  @Input() showGroupAvatar: boolean = true;
  @Input() showGroupIcon:boolean = true;
  @Input() showVideoIcon:boolean = false;
  @Input() isModerator: boolean;
  @Input() isAudience: boolean;
  @Input() activeTab: number;
  @Input() noAvatarTemplate: TemplateRef<any>;
  @Input() isArchived:boolean = false;
  @Output() loadedAvatar = new EventEmitter();
  isBroadcast: boolean;
  className: string;
  private isAlive$ = new Subject<boolean>();
  bare: string;
  isBlocked: boolean;
  translationKey: string = "HEADER_OFFLINE";
  @Input() showDismiss = false;
  @Input() showName = false;
  @Input() isInvited = false;
  @Input() isEncrypted = false;
  @Input() totalUnread = 0;
  @Input() atComponent: string;
  @Input() jid: string;
  @Input() hasExternalUsers:boolean = false;
  @Input() isVideoCall: boolean = false;
  name;
  isExternalUser: boolean;

  statusSubscription$;
  contactSubscription$;
  blockedListSubscription$;
  photoLastUpdateSubscription$;

  getUserStatusType = getUserStatusType;

  photoLastUpdate;

  @ViewChild("imgElement", { static: false }) imgElement: ElementRef;

  defaultAvatarHIN = CommonUtil.getFullUrl("/assets/img/hintalk-avatar-transparent.png");
  defaultAIAvatar = CommonUtil.getFullUrl("/assets/avatar/Vincenta.png");
  vincentAvatar = CommonUtil.getFullUrl("/assets/avatar/Vincent.png");
  isVideoMeeting: boolean;

  isImageLoading = false;
  isProcessing: boolean;
  jidChanges$ = new Subject();
  componentId: string;
  retryTime = 0;
  isAppOnline: boolean;
  tempAvatarUrl: any;
  realUrl: string;
  highResolutionUrl : string = "";
  botJid: any;

  @Input()
  set type(value) {
    this.isGroupChat = value === "groupchat";
  }

  constructor(private store: Store<TalkRootState>,
    private contactRepo: ContactRepository,
    private avatarRepo: AvatarRepository,
    private logger: LoggerService,
    private configService: ConfigService,
    private changeDetectionRef: ChangeDetectorRef) {
      this.botJid = this.configService.get("botJid");
      this.componentId = CommonUtil.randomId();
      this.jidChanges$.asObservable().pipe(throttleTime(200), distinctUntilChanged(), filter(v => !!v), takeUntil(this.isAlive$)).subscribe(v => {
        this.logger.info("jidChanges", v, this.atComponent, this.componentId);
        if (!!v && !this.isProcessing) {
          this.isProcessing = true;
          this.subscribeAvatarUpdate();
        }
      });
      this.store.select(getIsAppOnline).pipe(distinctUntilChanged(), takeUntil(this.isAlive$)).subscribe(v => {
        this.isAppOnline = v;
        // this.logger.info("[AvatarComponent] getIsAppOnline", this.bare, v);
      });
  }

  private handleJidChange(value) {
    if (!value) {
      return;
    }
    // this.logger.info("handleJidChange", value, this.bare, this.atComponent);

    this.bare = value.toLowerCase();
    this.isGroupChat = this.jid?.indexOf("@conference") !== -1;
    this.isVideoMeeting = CommonUtil.isVideoMeeting(this.jid);
    this.store.select(getDomain).pipe(filter(v => !!v), take(1)).subscribe(domain => {
      if (this.isGroupChat) {
        this.isExternalUser = value.indexOf(`@conference.${domain}`) === -1;
      } else {
        this.isExternalUser = value.indexOf(`@${domain}`) === -1;
      }
    });
    if (CommonUtil.isBroadcast(this.jid)) {
      this.isBroadcast = true;
    } else {
      this.isBroadcast = false;
    }
    let _text = CommonUtil.getAvatarText(this.bare);
    this.text = _text;
    this.bgcolor = CommonUtil.getAvatarBackground(_text);
    this.background = this.bgcolor;
    if (this.isHinTheme) {
      this.background = `#ffffff url(${this.defaultAvatarHIN}) no-repeat center center`;
    }

    this.name = this.contactRepo.getFullName(this.bare);
    this.text = CommonUtil.getAvatarText(this.name);
    this.className = this.bare.replace(/@/g, "-at-").replace(/\./g, "-");

    this.removeSubscribers();
    this.createSubscribers();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.highResolutionUrl = "";
    // this.logger.info("[AvatarComponent] ngOnChanges", changes, this.atComponent, this.componentId);
    if (changes.jid && changes.jid.currentValue) {
      this.bare = changes.jid.currentValue;
      this.tempAvatarUrl = this.avatarRepo.avatarInDB[this.bare];
      if (!this.tempAvatarUrl) {
        this.isAvatarExists = false;
      }
      this.subscribeAvatarUpdate();
      this.handleJidChange(this.bare);
    }
    if (this.atComponent === "chat-sidebar") {
        this.highResImage();
    }
  }

  ngOnDestroy() {
    this.isAlive$.next(false);
    this.isAlive$.complete();
    this.removeSubscribers();
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
  }

  private removeSubscribers() {
    if (this.statusSubscription$) {
      this.statusSubscription$.unsubscribe();
    }

    if (this.contactSubscription$) {
      this.contactSubscription$.unsubscribe();
    }

    if (this.blockedListSubscription$) {
      this.blockedListSubscription$.unsubscribe();
    }
  }

  private createSubscribers() {
    // this.logger.info("[AvatarComponent][createSubscribers]", this.bare, this.isGroupChat, this.hidestatus);
    if (!this.isGroupChat && !this.hidestatus) {
      // display own status as online always
      if (this.contactRepo.userJID && this.contactRepo.userJID.bare === this.bare) {
        const status = 1;
        let _status = this.getUserStatusType(status).slug;
        this.status = _status;
        this.translationKey = this.getUserStatusType(status).translation_key;
        this.changeDetectionRef.markForCheck();

      // other users status
      } else {
        this.statusSubscription$ = this.store.select(state => getContactStatusById(state, this.bare)).pipe(takeUntil(this.isAlive$)).subscribe(status => {
          let _status = this.getUserStatusType(status).slug;
            // this.logger.info("[AvatarComponent][getUserStatus]", this.bare, this.status, _status, status);
          if (this.status !== _status) {
            this.status = _status;
            this.translationKey = this.getUserStatusType(status).translation_key;
            this.changeDetectionRef.markForCheck();
          }
        });
      }

      this.blockedListSubscription$ = this.store.select(getBlockedBareIds).pipe(takeUntil(this.isAlive$)
        , map(blockedBareIds => blockedBareIds.indexOf(this.bare) !== -1)).subscribe(isBlocked => {
          if (isBlocked !== this.isBlocked) {
            this.isBlocked = isBlocked;
            this.changeDetectionRef.markForCheck();
          }
        });
    }
  }

  subscribeAvatarUpdate() {
    if (this.photoLastUpdateSubscription$) {
      this.photoLastUpdateSubscription$.unsubscribe();
    }
    // this.logger.info("[AvatarComponent-" + this.bare + "[subscribeAvatarUpdate]", this.bare, this.isBroadcast);
    if (!this.isBroadcast) {
      const avInDB = this.avatarRepo.avatarInDB[this.bare];
      this.logger.info("[AvatarComponent-" + this.bare + "[subscribeAvatarUpdate] loaded avatarRepo Cache? ", !!avInDB, this.avatarRepo.avatarsLoaded, avInDB);
      if (!!avInDB && this.avatarRepo.avatarsLoaded) {
        // this.logger.info("[AvatarComponent-" + this.bare + "[subscribeAvatarUpdate] loaded from avatarRepo Cache");
        this.isAvatarExists = true; // to display image first
        this.tempAvatarUrl = avInDB;
        this.avatarUrl = avInDB;
        this.isImageLoading = true;
        this.changeDetectionRef.markForCheck();
      } else {
        if (this.avatarRepo.avatarsLoaded) {
          if (!avInDB) {
            this.tempAvatarUrl = "";
            this.isAvatarExists = false;
            this.isImageLoading = false;
            this.logger.info("[AvatarComponent-" + this.bare + "[fetchAvatarFromDatabase] remove avatar", this.bare);
            this.changeDetectionRef.markForCheck();
            setTimeout(() => {
              this.detectAvatarChange(true);
            }, 1500);

          } else {

            this.avatarRepo.fetchAvatarFromDatabase(this.bare).subscribe(dbres => {
              // this.logger.info("[AvatarComponent-" + this.bare + "[fetchAvatarFromDatabase]", this.bare, dbres );
              if (!!dbres && !!dbres.data) {
                this.isAvatarExists = true; // to display image first
                this.tempAvatarUrl = dbres.data;
                this.isImageLoading = true;
                this.changeDetectionRef.markForCheck();
              } else {
                this.tempAvatarUrl = "";
                this.isAvatarExists = false;
                this.isImageLoading = false;
                this.logger.info("[AvatarComponent-" + this.bare + "[fetchAvatarFromDatabase] remove avatar", this.bare, dbres );
                this.changeDetectionRef.markForCheck();
                setTimeout(() => {
                  this.detectAvatarChange(true);
                }, 1500);
              }
            });
          }
        } else {
          setTimeout(() => {
            this.detectAvatarChange(true);
          }, 1000);
        }
      }

      this.photoLastUpdateSubscription$ = this.store.select(state => getLastPhotoUpdate(state, this.bare)).pipe(distinctUntilChanged()).subscribe(photoLastUpdate => {
        // this.logger.info("[AvatarComponent-" + this.bare + "-photoLastUpdateSubscription]", this.photoLastUpdate, photoLastUpdate, this.bare, this.size);
          const updateRequired = ((!!this.photoLastUpdate && (photoLastUpdate !== this.photoLastUpdate)) || (photoLastUpdate < 0));
          const highres =  (this.size === "tile-view" || this.size === "preview" || this.size === "preview-video" || this.size === "big-profile" || this.atComponent === "chat-sidebar");
          this.photoLastUpdate = photoLastUpdate;

          if (updateRequired || highres) {
            if (environment.isCordova && environment.enableOauthLogin) {
              if (this.avatarRepo.isNetworkOnline()) {
                this.configService.isProfileLoaded$.pipe(filter(v => !!v), take(1)).subscribe(() => {
                  this.detectAvatarChange(true);
                });
              }
            } else {
              this.detectAvatarChange(true);
            }
          }
      });
    }

    this.store.select(state => getConversationById(state, this.jid))
    .pipe(take(1))
    .subscribe((conv: Conversation) => {
      if(conv && conv.archived !== undefined) {
        this.isArchived = (this.contactRepo.userJID && (this.jid === this.contactRepo.userJID.bare)) ? false : conv.archived;
      }
    });
  }

  detectAvatarChange(force?: boolean): void {
    if (this.bare === "all") {
      return;
    }
    if (!this.isBroadcast && this.bare) {
      const highres = (this.size === "tile-view" || this.size === "preview" || this.size === "preview-video" || this.size === "big-profile" || this.atComponent === "chat-sidebar");
      if (highres) {
        this.highResImage();
      }
      const newAvatar = this.buildAvatarUrl(true);
      if (!this.tempAvatarUrl || force || highres || this.realUrl !== newAvatar) {
        this.avatarUrl = newAvatar;
        this.realUrl = newAvatar;
      }
      if (!!newAvatar && newAvatar.startsWith("https")) {
        this.logger.info("[AvatarComponent-" + this.bare + "detectAvatarChange]", this.bare, newAvatar);
      }
      if (newAvatar &&  environment.enableOauthLogin  && (environment.isCordova && CommonUtil.isOnIOS())) {
        if (!this.avatarRepo.avatars[newAvatar] || force) {
          this.avatarRepo.storeNewAvatar(this.avatarUrl, this.bare).subscribe(v => {
            this.tempAvatarUrl = v;
            this.avatarUrl = v;
            this.isImageLoading = false;
            this.isAvatarExists = true;
            this.changeDetectionRef.markForCheck();
          });
        }
      }
      this.changeDetectionRef.markForCheck();
    } else {
      this.avatarUrl = null;
      this.changeDetectionRef.markForCheck();
    }
  }

  private buildAvatarUrl(force?) {
    if (this.photoLastUpdate === -1) {
      if (this.retryTime > 2) {
        // no avatar
        this.displayNoAvatar();
        return null;
      }
    }

    if (!this.photoLastUpdate) {
      this.avatarRepo.upgradeAvatar(this.bare);
    }
    let avatarVersion = this.avatarVersion();
    let avatarName = this.avatarRepo.buildTargetHash(this.bare);
    if (this.size === "tile-view" || this.size === "preview" || this.size === "preview-video"  || this.size === "big-profile" || this.atComponent === "chat-sidebar") {
      avatarName = `${avatarName}-420`;
      avatarVersion = "?ver=" + Math.abs(new Date().getTime());
    }
    const avUrl = this.configService.avatarServiceUrl + "/" + avatarName + ".jpg" + avatarVersion;
    const avInDB = this.avatarRepo.avatarInDB[this.bare];
    // this.logger.info("[AvatarComponent-" + this.bare + "[buildAvatarUrl]", this.bare, avUrl, !!avInDB, this.retryTime, this.size);
    if ((avatarVersion === "?ver=1") && !avInDB) {
      this.displayNoAvatar();
      return null;
    }
    if (this.size === "tile-view" || this.size === "preview" || this.size === "preview-video" || this.size === "big-profile" || this.atComponent === "chat-sidebar") {
      return CommonUtil.translateHINFileURL(avUrl);
    }
    return !!avInDB ? avInDB : CommonUtil.translateHINFileURL(avUrl);
  }

  private avatarVersion() {
    return this.photoLastUpdate ? `?ver=${Math.abs(this.photoLastUpdate)}` : "";
  }

  imgOnLoad() {
    // this.logger.info("[AvatarComponent-" + this.bare + "[AvatarComponent][imgOnLoad]", this.bare, this.atComponent);
    this.loadedAvatar.emit(this.avatarUrl);
    if (this.avatarUrl?.startsWith("https://") && (!(this.size === "tile-view" || this.size === "preview" || this.size === "preview-video" || this.size === "big-profile" || this.atComponent === "chat-sidebar"))) {
      // this.logger.info("[AvatarComponent][imgOnLoad] ", this.avatarUrl);
      this.avatarRepo.storeNewAvatar(this.avatarUrl, this.bare).subscribe(v => {
        this.tempAvatarUrl = v;
      });
    }
    this.onDefaultImageLoad();
  }

  onDefaultImageLoad() {
    this.avatarRepo.removeTargetForCheck(this.bare);
    this.isImageLoading = false;
    this.isAvatarExists = true;
    this.changeDetectionRef.markForCheck();
    setTimeout(() => {
      this.contactRepo.broadcastAvatarUpdate();
    }, 200);
  }

  private displayNoAvatar() {
    // this.logger.info("[AvatarComponent-" + this.bare + "[AvatarComponent][displayNoAvatar]", this.bare, this.avatarUrl, this.isAvatarExists, this.avatarRepo.isNetworkOnline(), this.isAppOnline);
    this.isImageLoading = false;
    this.isAvatarExists = false;
    this.avatarUrl = null;
    this.changeDetectionRef.markForCheck();
  }

  imgLoadOnError() {
    // this.logger.info("[AvatarComponent-" + this.bare + "[AvatarComponent][imgLoadOnError]", this.bare, this.avatarUrl);
    this.isImageLoading = false;
    this.displayNoAvatar();
    if (navigator.onLine) {
      this.avatarRepo.removeAvatar(this.bare);
      this.retryTime++;
      this.avatarRepo.addTargetForCheck(this.bare);
    } else {
      this.avatarRepo.addTargetForCheck(this.bare);
      this.store.select(getIsAppOnline).pipe(filter(v => !!v), take(1)).subscribe(() => {
        this.subscribeAvatarUpdate();
      });
    }
  }

  highResImage() {
    this.highResolutionUrl = this.buildAvatarUrl(true);
    this.changeDetectionRef.markForCheck();
  }

  highResolutionError(){
    this.highResolutionUrl = null;
  }
}
