<vnc-dialog *ngIf="!isMobileScreen" [classes]="['channel-upload-avatar']" headerText="{{ 'SELECT_AVATAR' | translate }}"
classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
</vnc-dialog>
<vnc-dialog *ngIf="isMobileScreen" [classes]="['channel-upload-avatar', 'channel-upload-avatar-mobile']" headerText="{{ 'SELECT_AVATAR' | translate }}"
[headerTemplate]="mobileHeaderTemplate" classes="fixed-size with-border with-border-radius" [footerTemplate]="footerTemplate" [bodyTemplate]="bodyTemplate">
</vnc-dialog>
<ng-template #mobileHeaderTemplate>
    <vnc-mobile-header *ngIf="hideCropper" style="width: 100%;" customClass="white-back-more" (backButtonClick)="close()" headerType="back-more" mobileHeading="{{ 'SELECT_AVATAR' | translate }}"></vnc-mobile-header>
    <vnc-mobile-header class="crop-avatar-header" style="width: 100%;" *ngIf="!hideCropper" headerType="dark-back-more" darkBackMorePlaceHolder="{{ 'BACK' | translate }}"
        (darkBackPress)="hideCropper=true">
    </vnc-mobile-header>
</ng-template>
<ng-template #bodyTemplate>
    <div class="main-body">
        <div class="left-item">
            <vnc-loader-circular [color]="'blue'" [size]="'xl'" class="avatar-upload-loader" *ngIf="!visible"></vnc-loader-circular>
            <div class="avatar-wrapper" [ngStyle]="{'visibility':visible ? 'visible' : 'hidden' }">
                <div class="cropper-container">
                    <vnc-image-cropper (onCropped)="imageCropped($event)" (onLoaded)="loadedImageForCrop($event)" [scale]="scale"
                        uploadLabel="{{ 'UPLOAD' | translate }}">
                    </vnc-image-cropper>
                </div>

                    <input title="" id="custom-input-select-avatar" type="file" style="display:none;" (change)="selectNewImage($event)" accept="image/*">
            </div>
        </div>

        <div class="right" *ngIf="!hideRightBar">
            <div class="item upload-image-button" (click)="selectPhoto()" role="button">
                <mat-icon role="img" fontset="mdi" fonticon="mdi-plus" class="mat-icon notranslate hoverable mdi mdi-plus"
                    aria-hidden="true" data-mat-icon-type="font" data-mat-icon-name="mdi-plus" data-mat-icon-namespace="mdi">
                </mat-icon>
            </div>
            <div class="item" *ngFor="let item of defaultCovers;index as i;">
                <vnc-gallery-component
                    [type]="'default'"
                    [isChecked]="i === indexItem ? true : false"
                    [coverImageURL]="item.url"
                    (itemClick)="selectIndexItem(i)"
                    [isMobile]="false"
                >
                </vnc-gallery-component>
            </div>

            <div class="item external-avatar-image" *ngFor="let item of externalCover;index as i;">
                <div class="image-holder relative">
                    <vnc-gallery-component [type]="'default'" [isChecked]="i === externalIndex ? true : false" [coverImageURL]="item.base64data | safe : 'resourceUrl' "
                    (itemClick)="selectIndexItem(i, true)" [isMobile]="false" mat-icon-button
                    >
                    </vnc-gallery-component>
                    <span class="absolute icon-delete" (click)="deleteChatBackgroundImage(item.id, i)" matTooltip="{{ 'REMOVE' | translate }}"><ng-container  [ngTemplateOutlet]="deleteIcon"></ng-container></span>
                    <mat-menu #removeExternalAvatarMenu="matMenu" class="external-avatar-remove-menu">
                        <button mat-menu-item (click)="removeExternalAvatar(i)">
                            <span class="external-avatar-remove-item">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <path
                                            d="M18,7 L18,18.2857143 C18,19.7746499 16.8086874,21 15.3333333,21 L15.3333333,21 L8.66666667,21 C7.19131256,21 6,19.7746499 6,18.2857143 L6,18.2857143 L6,7 L18,7 Z M16,9 L8,9 L8,18.2857143 C8,18.649428 8.25938319,18.9465317 8.57834889,18.9935231 L8.57834889,18.9935231 L8.66666667,19 L15.3333333,19 C15.6913126,19 16,18.6824929 16,18.2857143 L16,18.2857143 L16,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z"
                                            id="Combined-Shape" fill="#8B96A0"></path>
                                    </g>
                                </svg>
                                <span class="ml-4">{{ 'REMOVE' | translate }}</span>
                            </span>
                        </button>
                    </mat-menu>
                </div>

            </div>


        </div>

        <div class="image-cropper-scale-block" *ngIf="isMobileScreen && !hideCropper">
            <mat-slider [ngStyle]="{'visibility':hideCropper?'hidden':'visible'}" color="primary" [(ngModel)]="scale" min="0"
                step="0.01" max="1"></mat-slider>
        </div>
    </div>
</ng-template>
<ng-template #footerTemplate>

    <div class="footer-actions" *ngIf="!isMobileScreen && visible">
        <mat-slider   [ngStyle]="{'visibility':hideCropper?'hidden':'visible'}" color="primary" [(ngModel)]="scale"  [min]="min" step="0.01" [max]="max"></mat-slider>
        <span></span>
        <div class="buttons">
            <vnc-secondary-button [size]="'m'" label="{{ 'CANCEL_BUTTON' | translate }}" (onClick)="close()">
            </vnc-secondary-button>
            <vnc-primary-button [size]="'m'" (onClick)="cropImage()" [isDisabled]="disableButton"><span>{{ 'APPLY_BUTTON' | translate }}</span>
            </vnc-primary-button>
        </div>
    </div>
    <div class="footer-actions mobile-actions" *ngIf="isMobileScreen && !hideCropper">
        <vnc-icon-container (click)="removeSelectedImage()">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.95 5.05a1 1 0 0 1 0 1.414L13.414 12l5.536 5.536a1 1 0 0 1-1.414 1.414L12 13.414 6.464 18.95a1 1 0 1 1-1.414-1.414L10.586 12 5.05 6.464A1 1 0 0 1 6.464 5.05L12 10.586l5.536-5.536a1 1 0 0 1 1.414 0z" fill="#FFF" fill-rule="evenodd"/>
            </svg>
        </vnc-icon-container>
        <div class="user-fullname"></div>
        <vnc-icon-container (click)="cropImage()">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" fill="#FFF" fill-rule="evenodd"/>
            </svg>
        </vnc-icon-container>
    </div>
</ng-template>

<ng-template #deleteIcon>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Icon/product/delete-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M18,7 L6,7 L6,18.2857143 C6,19.7746499 7.19131256,21 8.66666667,21 L15.3333333,21 C16.8086874,21 18,19.7746499 18,18.2857143 L18,7 Z M8,9 L16,9 L16,18.2857143 C16,18.6824929 15.6913126,19 15.3333333,19 L8.66666667,19 L8.57834889,18.9935231 C8.25938319,18.9465317 8,18.649428 8,18.2857143 L8,9 Z M13,3 C13.7404864,3 14.3869825,3.40242038 14.7327215,4.00049436 L18,4 C18.5522847,4 19,4.44771525 19,5 C19,5.55228475 18.5522847,6 18,6 L6,6 C5.44771525,6 5,5.55228475 5,5 C5,4.44771525 5.44771525,4 6,4 L9.26760632,3.99992752 C9.61342606,3.40216612 10.2597476,3 11,3 L13,3 Z" id="Combined-Shape" fill="#8B96A0"></path>
        </g>
    </svg>
</ng-template>