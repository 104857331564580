export const newTicketData = {
    statusOptions : [
        {value: "new",                  viewValue: "New"},
        {value: "inProgress",           viewValue: "In Progress"},
        {value: "testing",              viewValue: "Testing"},
        {value: "review",               viewValue: "Review"},
        {value: "feedback",             viewValue: "Feedback"},
        {value: "onHold",               viewValue: "On Hold"},
        {value: "rejected",             viewValue: "Rejected"},
        {value: "old-resolved-closed",  viewValue: "OLD-Resolved-closed"},
        {value: "integrate",            viewValue: "Integrate"},
        {value: "invoice",              viewValue: "INVOICE"},
        {value: "pending",              viewValue: "Pending"},
        {value: "closed",               viewValue: "Closed"},
        {value: "awaitingApproval",     viewValue: "Awaiting APPROVAL"},
    ],

    typeOptions :  [
        {value: "meeting",              viewValue: "Meeting"},
        {value: "testing",              viewValue: "Testing"},
        {value: "testCase",             viewValue: "Test case"},
        {value: "newTestCase",          viewValue: "NW - Test case"},
        {value: "featureRequest",       viewValue: "Feature Request"},
        {value: "bugReport",            viewValue: "Bug Report"},
    ],

    priorityOptions :[
        {value: "low",                  viewValue: "Low"},
        {value: "normal",               viewValue: "Normal"},
        {value: "high",                 viewValue: "Heigh"},
        {value: "urgent",               viewValue: "Urgent"},
        {value: "immediate",            viewValue: "Immediate"},
    ],

    ticketDone :[
        {value: "0",                   viewValue: "% 0"},
        {value: "10",                  viewValue: "% 10"},
        {value: "20",                  viewValue: "% 20"},
        {value: "30",                  viewValue: "% 30"},
        {value: "40",                  viewValue: "% 40"},
        {value: "50",                  viewValue: "% 50"},
        {value: "60",                  viewValue: "% 60"},
        {value: "70",                  viewValue: "% 70"},
        {value: "80",                  viewValue: "% 80"},
        {value: "90",                  viewValue: "% 90"},
        {value: "100",                 viewValue: "% 100"},
    ],

    analyticOptions :[
        {value: "generic (not customer-bound)",             viewValue: "generic (not customer-bound)"},
        {value: "CS Upgrade_III-12",                        viewValue: "CS Upgrade_III-12"},
        {value: "CS Platform Redesign",                     viewValue: "CS Platform Redesign"},
        {value: "CloudDirectory Setup Testing Environment", viewValue: "CloudDirectory Setup Testing Environment"},
        {value: "CloudDirectory openERP HR Module",         viewValue: "CloudDirectory openERP HR Module"},
    ],

    projectList : [{
        id: 101,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name -1",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      },
      {
        id: 102,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name - 2",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      },
      {
        id: 103,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name - 3",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      },
      {
        id: 104,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name - 4",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      },
      {
        id: 105,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name - 5",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      },
      {
        id: 106,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name - 6",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      },
      {
        id: 107,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name - 7",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      },
      {
        id: 108,
        channel_id: "20988267-eec8-4c60-b245-a5763d023209",
        name: "Demo topic with name - 8",
        description: "<p>Edited the description now </p><p><br></p><p>ABC</p>",
        archived: false
      }],

    assigneeList:[],

    projectVersionsList: []

};


// [
//   {
//       "id": 91,
//       "login": "adam.young",
//       "firstname": "adam",
//       "lastname": "young",
//       "mail": "adam.young@dev.vnc.de",
//       "created_on": "2021-03-04T13:04:12Z",
//       "last_login_on": "2022-10-24T07:38:03Z",
//       "jid": "adam.young@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/4fdc920f8ce876d2476cecc89f9b1d74.jpg?ver=1669034562331"
//   },
//   {
//       "id": 1,
//       "login": "admin",
//       "firstname": "Redmine",
//       "lastname": "Admin",
//       "mail": "admin@dev.vnc.de",
//       "created_on": "2020-07-20T14:39:26Z",
//       "last_login_on": "2022-12-21T06:56:23Z",
//       "jid": "admin@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/9857fef647679217bf9d39cb54610d38.jpg?ver=1662717516787"
//   },
//   {
//       "id": 105,
//       "login": "admin.test2",
//       "firstname": "Admin",
//       "lastname": "Test2",
//       "mail": "admin.test2@dev.vnc.de",
//       "created_on": "2021-04-12T16:12:48Z",
//       "last_login_on": "2023-01-13T18:22:37Z",
//       "jid": "admin.test2@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/2158cc4d72927a1a681dcf48bc24bde3.jpg?ver=1672913979215"
//   },
//   {
//       "id": 108,
//       "login": "akash.javed",
//       "firstname": "akash",
//       "lastname": "javed",
//       "mail": "akash.javed@dev.vnc.de",
//       "created_on": "2021-04-16T14:49:42Z",
//       "last_login_on": null,
//       "jid": "akash.javed@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/3f7437fbcfde0a0b1679103fafb7db39.jpg?ver=1631522679257"
//   },
//   {
//       "id": 19,
//       "login": "alexey.aliokhin",
//       "firstname": "Alexey",
//       "lastname": "Aliokhin",
//       "mail": "alexey.aliokhin@dev.vnc.de",
//       "created_on": "2020-07-24T12:19:11Z",
//       "last_login_on": null,
//       "jid": "alexey.aliokhin@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/1e357780c1058cf70bc106ca05d48072.jpg?ver=1618290741130"
//   },
//   {
//       "id": 107,
//       "login": "ali.khan",
//       "firstname": "ali",
//       "lastname": "khan",
//       "mail": "ali.khan@dev.vnc.de",
//       "created_on": "2021-04-16T14:45:32Z",
//       "last_login_on": "2023-01-11T12:52:05Z",
//       "jid": "ali.khan@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/d655b3e26012a70db6bfa886b7a1bcaf.jpg?ver=1631522797615"
//   },
//   {
//       "id": 6079,
//       "login": "alyssa.parker",
//       "firstname": "Alyssa",
//       "lastname": "Parker",
//       "mail": "mla.test@vnc.biz",
//       "created_on": "2022-08-23T08:34:36Z",
//       "last_login_on": null,
//       "jid": "alyssa.parker@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/39a9e6e9b6755697532ffa749004c302.jpg?ver=1661243620374"
//   },
//   {
//       "id": 126,
//       "login": "amit.shah",
//       "firstname": "amit",
//       "lastname": "shah",
//       "mail": "amit.shah@dev.vnc.de",
//       "created_on": "2021-05-27T06:54:20Z",
//       "last_login_on": null,
//       "jid": "amit.shah@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/66b3d2293fdd9829e1c64854e7096016.jpg?ver=1623940698861"
//   },
//   {
//       "id": 20,
//       "login": "andrea",
//       "firstname": "Andrea",
//       "lastname": "Wörrlein",
//       "mail": "andrea@dev.vnc.de",
//       "created_on": "2020-07-24T12:19:19Z",
//       "last_login_on": "2022-08-07T19:49:08Z",
//       "jid": "andrea@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/0cbc997952252f3ac28fbbbb99c71c58.jpg?ver=1662115987406"
//   },
//   {
//       "id": 21,
//       "login": "anna.khizhnia",
//       "firstname": "Anna",
//       "lastname": "Khizhnia",
//       "mail": "anna.khizhnia@dev.vnc.de",
//       "created_on": "2020-07-24T12:19:25Z",
//       "last_login_on": "2021-06-23T07:29:28Z",
//       "jid": "anna.khizhnia@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/5c0a8ff2ff6faac460bf2e5f3f2aa4bd.jpg?ver=1630933931042"
//   },
//   {
//       "id": 151,
//       "login": "api.test1",
//       "firstname": "Api",
//       "lastname": "Test1",
//       "mail": "api.test1@dev.vnc.de",
//       "created_on": "2021-09-28T15:36:16Z",
//       "last_login_on": null,
//       "jid": "api.test1@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/1546be6df30dbc81d502e6135e31b47d.jpg?ver=1632846910848"
//   },
//   {
//       "id": 156,
//       "login": "api.test10",
//       "firstname": "nightwatch",
//       "lastname": "Test10",
//       "mail": "api.test10@dev.vnc.de",
//       "created_on": "2021-11-04T04:55:59Z",
//       "last_login_on": null,
//       "jid": "api.test10@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/99431575a266f9c5002e399b5764e684.jpg?ver=1636001752794"
//   },
//   {
//       "id": 157,
//       "login": "api.test11",
//       "firstname": "nightwatch",
//       "lastname": "Test11",
//       "mail": "api.test11@dev.vnc.de",
//       "created_on": "2021-11-04T04:57:22Z",
//       "last_login_on": null,
//       "jid": "api.test11@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/754d3ca8e0253e8c505e2e9f3ead398f.jpg?ver=1636001839722"
//   },
//   {
//       "id": 150,
//       "login": "api.test3",
//       "firstname": "Api",
//       "lastname": "Test3",
//       "mail": "api.test3@dev.vnc.de",
//       "created_on": "2021-09-28T15:26:30Z",
//       "last_login_on": null,
//       "jid": "api.test3@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/c0cac0791cd5a922c7d7eb2bf27391db.jpg?ver=1627908862589"
//   },
//   {
//       "id": 139,
//       "login": "api.test6",
//       "firstname": "Api",
//       "lastname": "Test6",
//       "mail": "api.test6@dev.vnc.de",
//       "created_on": "2021-09-13T16:41:06Z",
//       "last_login_on": null,
//       "jid": "api.test6@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/c565570bad208a27f51709b67bd7d4a7.jpg?ver=1627911541096"
//   },
//   {
//       "id": 130,
//       "login": "api.test7",
//       "firstname": "Api",
//       "lastname": "Test7",
//       "mail": "api.test7@dev.vnc.de",
//       "created_on": "2021-08-02T13:44:50Z",
//       "last_login_on": null,
//       "jid": "api.test7@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/9ebbf3074222b2d9b484e04e30cee970.jpg?ver=1627911975179"
//   },
//   {
//       "id": 132,
//       "login": "api.test8",
//       "firstname": "Api",
//       "lastname": "Test8",
//       "mail": "api.test8@dev.vnc.de",
//       "created_on": "2021-08-16T09:38:20Z",
//       "last_login_on": null,
//       "jid": "api.test8@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/32af73c7a223dc5b8c42577d57a395b2.jpg?ver=1629106700505"
//   },
//   {
//       "id": 129,
//       "login": "ashish.gohel",
//       "firstname": "ashish",
//       "lastname": "gohel",
//       "mail": "ashish.gohel@dev.vnc.de",
//       "created_on": "2021-07-09T09:27:34Z",
//       "last_login_on": null,
//       "jid": "ashish.gohel@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/f2a920c76d6c619c12cb281d074e7b16.jpg?ver=1625822915523"
//   },
//   {
//       "id": 110,
//       "login": "askari.razvi",
//       "firstname": "askari",
//       "lastname": "razvi",
//       "mail": "askari.razvi@dev.vnc.de",
//       "created_on": "2021-04-16T14:51:43Z",
//       "last_login_on": null,
//       "jid": "askari.razvi@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/2e57dee423d124e7403e7e18c686dcf1.jpg?ver=1631522695065"
//   },
//   {
//       "id": 13,
//       "login": "ben.neill",
//       "firstname": "Talal",
//       "lastname": "Waseem",
//       "mail": "ben.neill@dev.vnc.de",
//       "created_on": "2020-07-23T17:52:43Z",
//       "last_login_on": "2023-01-09T14:52:35Z",
//       "jid": "ben.neill@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/b2841312a19f4c9fe5e38936c912322b.jpg?ver=1673002345452"
//   },
//   {
//       "id": 122,
//       "login": "bernd",
//       "firstname": "bernd",
//       "lastname": "rodler",
//       "mail": "bernd@dev.vnc.de",
//       "created_on": "2021-05-19T05:14:10Z",
//       "last_login_on": null,
//       "jid": "bernd@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/ed0454fa0df1d6c1c2ac4a87d91130cd.jpg?ver=1609839280902"
//   },
//   {
//       "id": 22,
//       "login": "bernd.rodler",
//       "firstname": "Bernd",
//       "lastname": "Rodler",
//       "mail": "bernd.rodler@dev.vnc.de",
//       "created_on": "2020-07-24T12:19:33Z",
//       "last_login_on": "2022-02-11T15:40:19Z",
//       "jid": "bernd.rodler@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/bfe357dc9960919e8295d82bc1bcbca0.jpg?ver=1657199273721"
//   },
//   {
//       "id": 4629,
//       "login": "bhakti.pandya",
//       "firstname": "bhakti",
//       "lastname": "pandya",
//       "mail": "bhakti.pandya@dev.vnc.de",
//       "created_on": "2022-03-04T13:34:49Z",
//       "last_login_on": null,
//       "jid": "bhakti.pandya@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/208db174261315728930d866bfbc37ab.jpg?ver=1646400884243"
//   },
//   {
//       "id": 76,
//       "login": "bhoomi.somaiya",
//       "firstname": "bhoomi",
//       "lastname": "somaiya",
//       "mail": "somaiya@dev.vnc.de",
//       "created_on": "2020-12-12T05:02:59Z",
//       "last_login_on": null,
//       "jid": "bhoomi.somaiya@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Monthly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/01ca0ff96fab644b6f28b40b038ea467.jpg?ver=1607749363689"
//   },
//   {
//       "id": 23,
//       "login": "bilel.kedidi",
//       "firstname": "Bilel",
//       "lastname": "Kedidi",
//       "mail": "bilel.kedidi@dev.vnc.de",
//       "created_on": "2020-07-24T12:19:41Z",
//       "last_login_on": "2023-01-09T14:57:17Z",
//       "jid": "bilel.kedidi@dev.vnc.de",
//       "custom_fields": [
//           {
//               "id": 2,
//               "name": "Period of payment",
//               "value": "Weekly"
//           }
//       ],
//       "avatar_url": "https://avatar.vnc.biz/859ec779d78aa0751e602b9be1fcb4d7.jpg?ver=1656327783566"
//   }
// ]
